import { useState, useEffect, useCallback } from 'react';
import usePagination from '../_shared/usePagination';
import { AdminRolesFilters } from '../../components/roles/Roles.types';
import { PaginationParams } from '../../types/general-types';
import locationsService from '../../services/ScaleoAdminApiServices/LocationsService';

const useLocationData = (initialFilters: AdminRolesFilters) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const fetchData = useCallback(
    async (paginationParameters: PaginationParams) => {
      const allSystemLocations: any = await locationsService.searchLocations(paginationParameters, initialFilters);

      if (allSystemLocations) {
        setData(allSystemLocations.data.data);

        setTotalPages(allSystemLocations.data.totalPagesNumber);
      }
    },
    [initialFilters],
  );

  const { paginationParameters, setPaginationParameters, paginatorState, setPaginatorState } = usePagination(fetchData);

  useEffect(() => {
    setPaginatorState((prevState) => ({ ...prevState, totalPages }));
  }, [totalPages]);

  return {
    data,
    paginatorState,
    setPaginatorState,
    paginationParameters,
    setPaginationParameters,
    fetchData,
  };
};

export default useLocationData;
