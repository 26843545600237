import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { TieredMenu } from 'primereact/tieredmenu';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import deploymentsService from '../../services/ScaleoAdminApiServices/DeploymentsService';
import formatDateTime from '../_shared/formatDateTime';
import NewOrEditDeploymentDialog from './components/NewOrEditDeploymentDialog';
import DeleteDeploymentDialog from './components/DeleteDeploymentDialog';
import PublishDeploymentDialog from './components/PublishDeploymentDialog';
import FinishDeploymentDialog from './components/FinishDeploymentDialog';

const Deployments = () => {
  const [deployments, setDeployments] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isOpenNewOrEditDialog, setIsOpenNewOrEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenFinishDialog, setIsOpenFinishDialog] = useState(false);
  const [isOpenPublishDialog, setIsOpenPublishDialog] = useState(false);

  const fetchDeployments = useCallback(async () => {
    const deploymentsData = (await deploymentsService.getAllDeployments()).data;
    setDeployments(deploymentsData);
  }, []);

  useEffect(() => {
    void fetchDeployments();
  }, [fetchDeployments]);

  const handleCloseDialog = (changes) => {
    setIsOpenNewOrEditDialog(false);
    setIsOpenDeleteDialog(false);
    setIsOpenPublishDialog(false);
    setIsOpenFinishDialog(false);
    if (changes) void fetchDeployments();
  };

  const header = <div className="table-header">Lista aktualizacji</div>;

  const leftToolbarTemplate = () => (
    <Button
      label="Dodaj"
      icon="pi pi-plus"
      className="p-button-success p-mr-2"
      onClick={() => {
        setSelectedRow(null);
        setIsOpenNewOrEditDialog(true);
      }}
    />
  );

  const textBodyTemplate = (rowData, column) => rowData[column.field];

  const dateBodyTemplate = (rowData, column) => formatDateTime(rowData[column.field]);

  const statusBodyTemplate = (rowData, column) => (
    <>
      {rowData[column.field] === 'DRAFT' && (
        <Tag className="p-mr-2" icon="pi pi-calendar" severity="info" value="Wersja robocza"></Tag>
      )}
      {rowData[column.field] === 'SCHEDULED' && (
        <Tag className="p-mr-2" icon="pi pi-calendar" severity="info" value="Zaplanowana"></Tag>
      )}
      {rowData[column.field] === 'FINISHED' && (
        <Tag className="p-mr-2" icon="pi pi-check" severity="success" value="Zakończona"></Tag>
      )}
    </>
  );

  const actionBodyTemplate = (rowData) => <ActionBodyTemplateComponent rowData={rowData} />;

  const ActionBodyTemplateComponent = ({ rowData }) => {
    const items = [];
    const menu = useRef(null);

    const handleClickItem = (value) => {
      setSelectedRow(rowData);

      switch (value) {
        case 'edit':
          setIsOpenNewOrEditDialog(true);
          break;
        case 'publish':
          setIsOpenPublishDialog(true);
          break;
        case 'finish':
          setIsOpenFinishDialog(true);
          break;
        case 'delete':
          setIsOpenDeleteDialog(true);
          break;
        default:
          break;
      }
    };

    items.push({ label: 'Edytuj', command: () => handleClickItem('edit'), icon: 'pi pi-pencil' });
    rowData.status === 'DRAFT' &&
      items.push({ label: 'Opublikuj', command: () => handleClickItem('publish'), icon: 'pi pi-check-circle' });
    rowData.status === 'SCHEDULED' &&
      items.push({ label: 'Zakończ', command: () => handleClickItem('finish'), icon: 'pi pi-check-circle' });
    items.push({ label: 'Usuń', command: () => handleClickItem('delete'), icon: 'pi pi-trash' });

    return (
      rowData.status !== 'FINISHED' && (
        <>
          <TieredMenu model={items} popup ref={menu} />
          <Button label="Opcje" onClick={(event) => menu.current.toggle(event)} />
        </>
      )
    );
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive deployments-datatable">
            <div className="card datatable-card">
              <DataTable
                value={deployments}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortField="startDate"
                sortOrder={-1}
              >
                <Column
                  field="version"
                  header="Wersja"
                  body={textBodyTemplate}
                  style={{ width: '8em' }}
                  sortable
                ></Column>
                <Column
                  field="startDate"
                  header="Od"
                  body={dateBodyTemplate}
                  style={{ width: '14em' }}
                  sortable
                ></Column>
                <Column field="endDate" header="Do" body={dateBodyTemplate} style={{ width: '14em' }} sortable></Column>
                <Column
                  field="description"
                  header="Informacja dla użytkowników systemu Scaleo"
                  body={textBodyTemplate}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  body={statusBodyTemplate}
                  style={{ width: '12em' }}
                  sortable
                ></Column>
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
            </div>
          </div>
          {isOpenNewOrEditDialog && (
            <NewOrEditDeploymentDialog
              visible={isOpenNewOrEditDialog}
              closeDialog={handleCloseDialog}
              item={selectedRow}
            />
          )}
          {isOpenDeleteDialog && (
            <DeleteDeploymentDialog visible={isOpenDeleteDialog} closeDialog={handleCloseDialog} item={selectedRow} />
          )}
          {isOpenPublishDialog && (
            <PublishDeploymentDialog visible={isOpenPublishDialog} closeDialog={handleCloseDialog} item={selectedRow} />
          )}
          {isOpenFinishDialog && (
            <FinishDeploymentDialog visible={isOpenFinishDialog} closeDialog={handleCloseDialog} item={selectedRow} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Deployments;
