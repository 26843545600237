export enum UserActivationStatus {
  ACTIVE = 'ACTIVE',
  INVITATION_SENT = 'INVITATION_SENT',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
}

export interface User {
  id: string;
  customerId: string;
  email: string;
  firstName: string;
  lastName: string;
  status: UserActivationStatus;
  createdAt: Date;
}
