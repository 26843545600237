import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useEffect } from 'react';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { CustomAutoComplete } from '../_shared/CustomComponents';
import { AdminUserModalProps } from './AdminUsers.types';
import { AdminRole } from '../roles/Roles.types';

const mapAdminRole = (roleId: string, adminRoles: AdminRole[]) => {
  return adminRoles.find((role) => role.id === roleId);
};

const AdminUserModal = ({ adminUserData, adminRoles, closeModalHandler, formik, updateForm }: AdminUserModalProps) => {
  const hideDialog = () => {
    updateForm();
    closeModalHandler();
  };

  const handleRoleChange = (e: any) => {
    formik.setFieldValue('role', e?.value);
  };

  useEffect(() => {
    if (adminUserData?.id) {
      updateForm({
        id: adminUserData.id as any,
        firstName: adminUserData.firstName,
        lastName: adminUserData.lastName,
        email: adminUserData.email ? adminUserData.email : '',
        newPassword: false,
        password: undefined,
        confPassword: '',
        role: mapAdminRole(adminUserData.roleId, adminRoles),
      });
    } else {
      updateForm();
    }
  }, []);

  const userDialogFooter = (
    <>
      <Button type="reset" label="Anuluj" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button
        type="submit"
        label="Zapisz"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          formik.submitForm().then(() => {
            if (!Object.keys(formik.errors).length) {
              closeModalHandler();
            }
          });
        }}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <Dialog
        visible={true}
        header={'Dane administratora systemowego'}
        modal
        className="p-fluid"
        footer={userDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="p-col-10">
            <div className="p-field">
              <label htmlFor="email">{'Adres e-mail'}</label>
              <InputText
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                readOnly={formik.values.id ? true : false}
                disabled={formik.values.id ? true : false}
              />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>
            <div className="p-field">
              {formik.values.id ? (
                <div className="p-field-checkbox">
                  <Checkbox id="newPassword" checked={formik.values.newPassword} onChange={formik.handleChange} />
                  <label htmlFor="newPassword">{'Nowe hasło'}</label>
                </div>
              ) : null}
            </div>
            {formik.values.newPassword || !formik.values.id ? (
              <>
                <div className="p-field">
                  <label htmlFor="password">{'Hasło'}</label>
                  <Password
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    feedback={false}
                    toggleMask
                  />
                  <FormErrorMessage fieldName="password" formikInstance={formik} />
                </div>
                <div className="p-field">
                  <label htmlFor="confPassword">{'Potwierdź hasło'}</label>
                  <Password
                    id="confPassword"
                    name="confPassword"
                    value={formik.values.confPassword}
                    onChange={formik.handleChange}
                    feedback={false}
                  />
                  <FormErrorMessage fieldName="confPassword" formikInstance={formik} />
                </div>
                <hr />
              </>
            ) : null}
            <div className="p-field">
              <label htmlFor="firstName">{'Imię'}</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="firstName" formikInstance={formik} />
            </div>
            <div className="p-field">
              <label htmlFor="lastName">{'Nazwisko'}</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="lastName" formikInstance={formik} />
            </div>
            <div className="p-field">
              <label htmlFor="role">{'Rola administratora'}</label>
              <CustomAutoComplete
                id="role"
                value={formik.values.role}
                handler={handleRoleChange}
                source={adminRoles}
                lookupField={undefined}
                inputStyle={undefined}
                customSearchBranch={undefined}
                customItemTemplate={undefined}
                placeholder={undefined}
                scrollHeight={undefined}
                tooltip={undefined}
                disabled={undefined}
                forceSelection={undefined}
                onBlur={undefined}
              />
              <FormErrorMessage fieldName="role" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default AdminUserModal;
