import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import React, { useEffect, useRef, useState } from 'react';

import DataTableFilterElements from '../_shared/DataTableFilterElements';
import formatDateTime from '../_shared/formatDateTime';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import LocationDialog from './LocationDialog';
import { Location } from '../../types/location';
import useLocationData from '../../hooks/locations/useLocationData';
import useLocationForm from '../../hooks/locations/useLocationForm';
import LocationsDeleteDialog from './LocationsDeleteDialog';

const INIT_FILTERS = {
  locationName: null,
  shortName: null,
  customerName: null,
  city: null,
};

const Locations = () => {
  const dt = useRef(null);

  const [filters, setFilters] = useState<any>(INIT_FILTERS);

  const [selectedRow, setSelectedRow] = useState<Location | null>(null);
  const [selectedRows, setSelectedRows] = useState<Location[] | []>([]);

  const [isOpenLocationDialog, setIsOpenLocationDialog] = useState(false);
  const [isOpenLocationsDeleteDialog, setIsOpenLocationsDeleteDialog] = useState(false);

  const {
    data: locationsData,
    paginatorState,
    setPaginatorState,
    paginationParameters,
    setPaginationParameters,
    fetchData,
  } = useLocationData(filters);

  const { formik, updateForm } = useLocationForm(fetchData, paginationParameters);

  useEffect(() => {
    void fetchData(paginationParameters);
  }, [JSON.stringify(filters), paginationParameters]);

  const handleClickClearFilters = () => {
    setFilters({
      locationName: null,
      shortName: null,
      customerName: null,
      city: null,
    });
  };

  const header = (
    <div className="table-header">
      Lista oddziałów &#160;&#160;
      <Button
        label="Wyczyść filtry"
        icon="pi pi-filter-slash"
        className="p-button-outlined p-mr-2"
        onClick={handleClickClearFilters}
      />
    </div>
  );

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setSelectedRow(null);

      setIsOpenLocationDialog(true);
    };

    return (
      <>
        <Button label="Dodaj" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={handleClickNew} />
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return <></>;
  };

  const actionBodyTemplate = (rowData: Location) => {
    const handleClickEditLocationRow = () => {
      setSelectedRow(rowData);

      setIsOpenLocationDialog(true);
    };

    const handleClickDeleteLocationRow = () => {
      setSelectedRow(rowData);

      setIsOpenLocationsDeleteDialog(true);
    };

    return (
      <div className="actions" style={{ width: '9vw', marginLeft: '-3vw' }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={handleClickEditLocationRow}
        />
        <Button
          icon="pi pi-trash"
          className={`p-button-rounded  ${rowData?.isUsed ? 'p-disabled' : 'p-button-warning'}`}
          disabled={rowData?.isUsed}
          onClick={handleClickDeleteLocationRow}
        />
      </div>
    );
  };

  const createdAtBodyTemplate = (rowData: Location) => {
    return formatDateTime(rowData.createdAt);
  };

  const closeLocationDialogHandler = () => {
    setIsOpenLocationDialog(false);

    setSelectedRow(null);
  };

  const handleCloseLocationsDeleteDialog = () => {
    setIsOpenLocationsDeleteDialog(false);

    void fetchData(paginationParameters);
  };

  const handleChangeFilter = (value: string, name: string) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    setFilters(newFilters);
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const handleSelectionChange = (e: any) => {
    setSelectedRows(e.value);
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          {isOpenLocationDialog && (
            <LocationDialog
              closeDialogHandler={closeLocationDialogHandler}
              locationData={selectedRow}
              formik={formik}
              updateForm={updateForm}
            />
          )}

          {isOpenLocationsDeleteDialog && selectedRow && (
            <LocationsDeleteDialog
              location={selectedRow}
              closeLocationDeleteDialogHandler={handleCloseLocationsDeleteDialog}
            />
          )}

          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                ref={dt}
                value={locationsData}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                removableSort
                rowHover
                rows={paginatorState.rows}
                paginator
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                emptyMessage="Brak rekordów"
                onSort={handleSort}
                selection={selectedRows}
                onSelectionChange={(e) => handleSelectionChange(e)}
              >
                <Column
                  field="name"
                  header="Nazwa oddziału"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.name}
                      onChangeFilter={handleChangeFilter}
                      name="name"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column
                  field="shortName"
                  header="Skrócona nazwa"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.shortName}
                      onChangeFilter={handleChangeFilter}
                      name="shortName"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column field="city" header="Miasto"></Column>
                <Column
                  field="customer.companyName"
                  header="Klient"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.customerName}
                      onChangeFilter={handleChangeFilter}
                      name="customerName"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column body={createdAtBodyTemplate} field="createdAt" header="Data utworzenia" sortable></Column>
                <Column body={actionBodyTemplate} style={{ width: '100px', textAlign: 'center' }}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Locations;
