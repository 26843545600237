import classNames from 'classnames';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import React, { useEffect, useState, SyntheticEvent, useCallback } from 'react';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import './App.scss';
import AppMenu from './AppMenu';
import AppTopbar from './AppTopbar';
import { AxiosConfigContainer } from './components/AxiosConfig';
import Customers from './components/customers/Customers';
import ScaleoUsers from './components/scaleoUsers/ScaleoUsers';
import ServiceUsers from './components/serviceUsers/ServiceUsers';
import { scaleoModeContext } from './services/ScaleoModeContext';
import Deployments from './components/deployments/Deployments';
import NodeModels from './components/nodeModels/NodeModels';
import Licenses from './components/licenses/Licenses';
import AdminUsers from './components/adminUsers/AdminUsers';
import Roles from './components/roles/Roles';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from './store/user-slice';
import adminUsersService from './services/ScaleoAdminApiServices/AdminUsersService';
import { hasOneOfPermissions, hasPermissions } from './components/_shared/hasPermission';
import UserProfile from './components/userProfile/UserProfile';
import PrimeReact from 'primereact/api';
import Stations from './components/Stations/Stations';
import Locations from './components/locations/Locations';
import { Recognitions } from './components/Recognitions/Recognitions';
import { Permission } from './enums/permission.enum';

/**
 * IMPORTANT! Prime react has problem with random z-indexes.
 * Here we specify what zIndexes elements will have.
 * Consider it as a Prime React z index main z-index config.
 */
PrimeReact.autoZIndex = false;
PrimeReact.zIndex = {
  modal: 1000,
  overlay: 1200,
  menu: 800,
  tooltip: 1100,
  toast: 1500,
};

scaleoModeContext().appConfigurer();

const App = () => {
  const [layoutMode, setLayoutMode] = useState('static');
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] = useState(false);
  const [inlineUser, setInlineUser] = useState(false);
  const [activeTopbarItem, setActiveTopbarItem] = useState<string | null>(null);
  const [inlineUserMenuActive, setInlineUserMenuActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  const loggedUserDetails = useSelector((state: any) => state.user.details);

  const fetchUserDetails = useCallback(() => {
    void adminUsersService.getLoggedUserDetails().then((response: any) => {
      const loggedUserDetails = response.data;

      dispatch(userActions.shouldRefreshLoggedUserContext(false));
      dispatch(userActions.setLoggedUserDetails(loggedUserDetails));
      setInitialized(true);
    });
  }, [dispatch]);

  useEffect(() => {
    fetchUserDetails();
  }, [fetchUserDetails]);

  const menu = [
    {
      label: 'Panel Zarządzania',
      icon: 'pi pi-fw pi-map',
      visible: hasOneOfPermissions(
        [Permission.ADMIN_USERS_MANAGEMENT, Permission.ROLES_MANAGEMENT, Permission.ACCESS_TO_EVENT_LOG],
        loggedUserDetails.permissions,
      ),
      items: [
        {
          label: 'Lista administratorów',
          icon: 'pi po-fw pi-list',
          visible: hasPermissions([Permission.ADMIN_USERS_MANAGEMENT], loggedUserDetails.permissions),
          to: '/admin-users',
        },
        {
          label: 'Role',
          icon: 'pi po-fw pi-unlock',
          visible: hasPermissions([Permission.ROLES_MANAGEMENT], loggedUserDetails.permissions),
          to: '/roles',
        },
      ],
    },
    {
      label: 'MENU',
      icon: 'pi pi-fw pi-map',
      visible: hasOneOfPermissions(
        [
          Permission.CUSTOMER_MANAGEMENT,
          Permission.LOCATIONS_MANAGEMENT,
          Permission.STATIONS_MANAGEMENT,
          Permission.SERVICE_USERS_MANAGEMENT,
          Permission.SCALEO_USERS_MANAGEMENT,
          Permission.NODE_MODELS_MANAGEMENT,
          Permission.DEPLOYMENTS_MANAGEMENT,
        ],
        loggedUserDetails.permissions,
      ),
      items: [
        {
          label: 'Klienci',
          icon: 'pi po-fw pi-briefcase',
          visible: hasPermissions([Permission.CUSTOMER_MANAGEMENT], loggedUserDetails.permissions),
          to: '/',
        },
        {
          label: 'Oddziały',
          icon: 'pi po-fw pi-briefcase',
          visible: hasPermissions([Permission.LOCATIONS_MANAGEMENT], loggedUserDetails.permissions),
          to: '/locations',
        },
        {
          label: 'Licencje',
          icon: 'pi po-fw pi-desktop',
          visible: hasPermissions([Permission.CUSTOMER_MANAGEMENT], loggedUserDetails.permissions),
          to: '/licenses',
        },
        {
          label: 'Stanowiska',
          icon: 'pi po-fw pi-cog',
          visible: hasPermissions([Permission.STATIONS_MANAGEMENT], loggedUserDetails.permissions),
          to: '/stations',
        },
        {
          label: 'Serwisanci',
          icon: 'pi po-fw pi-cog',
          visible: hasPermissions([Permission.SERVICE_USERS_MANAGEMENT], loggedUserDetails.permissions),
          to: '/service-users',
        },
        {
          label: 'Użytkownicy Scaleo',
          icon: 'pi po-fw pi-users',
          visible: hasPermissions([Permission.SCALEO_USERS_MANAGEMENT], loggedUserDetails.permissions),
          to: '/scaleo-users',
        },
        {
          label: 'Konfiguracja urządzeń',
          icon: 'pi po-fw pi-bookmark',
          visible: hasPermissions([Permission.NODE_MODELS_MANAGEMENT], loggedUserDetails.permissions),
          to: '/node-models',
        },
        {
          label: 'Aktualiazcje',
          icon: 'pi po-fw pi-arrow-circle-down',
          visible: hasPermissions([Permission.DEPLOYMENTS_MANAGEMENT], loggedUserDetails.permissions),
          to: '/deployments',
        },
      ],
    },
    {
      label: 'ANPR',
      icon: 'pi pi-fw pi-map',
      visible: hasOneOfPermissions([Permission.RECOGNITIONS], loggedUserDetails.permissions),
      items: [
        {
          label: 'Rozpoznania',
          icon: 'pi po-fw pi-list',
          visible: hasPermissions([Permission.RECOGNITIONS], loggedUserDetails.permissions),
          to: '/recognitions',
        },
      ],
    },
  ];

  const routers = [
    {
      path: '/admin-users',
      component: hasPermissions([Permission.ADMIN_USERS_MANAGEMENT], loggedUserDetails.permissions) && <AdminUsers />,
    },
    {
      path: '/roles',
      component: hasPermissions([Permission.ROLES_MANAGEMENT], loggedUserDetails.permissions) && <Roles />,
    },
    {
      path: '/',
      component: hasPermissions([Permission.CUSTOMER_MANAGEMENT], loggedUserDetails.permissions) && <Customers />,
    },
    {
      path: '/locations',
      component: hasPermissions([Permission.LOCATIONS_MANAGEMENT], loggedUserDetails.permissions) && <Locations />,
    },
    {
      path: '/customers',
      component: hasPermissions([Permission.CUSTOMER_MANAGEMENT], loggedUserDetails.permissions) && <Customers />,
    },
    {
      path: '/licenses',
      component: hasPermissions([Permission.CUSTOMER_MANAGEMENT], loggedUserDetails.permissions) && <Licenses />,
    },
    {
      path: '/service-users',
      component: hasPermissions([Permission.SERVICE_USERS_MANAGEMENT], loggedUserDetails.permissions) && (
        <ServiceUsers />
      ),
    },
    {
      path: '/scaleo-users',
      component: hasPermissions([Permission.SCALEO_USERS_MANAGEMENT], loggedUserDetails.permissions) && <ScaleoUsers />,
    },
    {
      path: '/node-models',
      component: hasPermissions([Permission.NODE_MODELS_MANAGEMENT], loggedUserDetails.permissions) && <NodeModels />,
    },
    {
      path: '/deployments',
      component: hasPermissions([Permission.DEPLOYMENTS_MANAGEMENT], loggedUserDetails.permissions) && <Deployments />,
    },
    {
      path: '/user-profile',
      component: <UserProfile />,
    },
    {
      path: '/stations',
      component: <Stations />,
    },
    {
      path: '/recognitions',
      component: <Recognitions />,
    },
  ];

  let topbarItemClick: boolean;
  let menuClick: boolean;
  let userMenuClick: boolean;
  let configClick = false;

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  const onDocumentClick = () => {
    if (!topbarItemClick) {
      setActiveTopbarItem(null);
    }

    if (!userMenuClick && isSlim() && !isMobile()) {
      setInlineUserMenuActive(false);
    }

    if (!menuClick) {
      if (isHorizontal() || isSlim()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    topbarItemClick = false;
    menuClick = false;
    userMenuClick = false;
    configClick = false;
  };

  const onMenuButtonClick = (event: SyntheticEvent | null) => {
    menuClick = true;

    if (layoutMode === 'overlay') {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive((prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive);
      onLayoutModeChange(layoutMode === 'static' ? 'slim' : 'static');
    } else {
      setStaticMenuMobileActive((prevStaticMenuMobileActive) => !prevStaticMenuMobileActive);
      if (staticMenuMobileActive) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }

    event?.preventDefault && event.preventDefault();
  };

  const onTopbarItemClick = (event: any) => {
    topbarItemClick = true;

    if (activeTopbarItem === event.item) setActiveTopbarItem(null);
    else setActiveTopbarItem(event.item);

    event.originalEvent.preventDefault();
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onInlineUserClick = () => {
    userMenuClick = true;
    setInlineUserMenuActive((prevInlineUserMenuActive) => !prevInlineUserMenuActive);
    setMenuActive(false);
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(
        new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'),
        ' ',
      );
    }
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const onMenuItemClick = (event: any) => {
    if (!event.item.items) {
      hideOverlayMenu();
    }
    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
    setInlineUserMenuActive(false);
  };

  const isDesktop = () => {
    return window.innerWidth > 896;
  };

  const isMobile = () => {
    return window.innerWidth <= 1025;
  };

  const isHorizontal = () => {
    return layoutMode === 'horizontal';
  };

  const isSlim = () => {
    return layoutMode === 'slim';
  };

  const onLayoutModeChange = (layoutMode: string) => {
    setLayoutMode(layoutMode);
    setStaticMenuDesktopInactive(false);
    setOverlayMenuActive(false);

    if (layoutMode === 'horizontal' && inlineUser) {
      setInlineUser(false);
    }
  };

  const layoutClassName = classNames(
    'layout-wrapper',
    {
      'layout-horizontal': layoutMode === 'horizontal',
      'layout-overlay': layoutMode === 'overlay',
      'layout-static': layoutMode === 'static',
      'layout-slim': layoutMode === 'slim',
      'layout-menu-light': true,
      'layout-menu-dark': false,
      'layout-overlay-active': overlayMenuActive,
      'layout-mobile-active': staticMenuMobileActive,
      'layout-static-inactive': staticMenuDesktopInactive,
      'layout-rtl': false,
      'p-input-filled': false,
      'p-ripple-disabled': true,
    },
    'layout-topbar-blue',
  );

  const inlineUserTimeout = layoutMode === 'slim' ? 0 : { enter: 1000, exit: 450 };

  return (
    <div className={layoutClassName} onClick={onDocumentClick}>
      <AxiosConfigContainer />
      {initialized && (
        <>
          <React.Fragment>
            <AppTopbar
              activeTopbarItem={activeTopbarItem}
              inlineUser={inlineUser}
              onMenuButtonClick={onMenuButtonClick}
              onTopbarItemClick={onTopbarItemClick}
            />
            <div className="layout-menu-container" onClick={onMenuClick}>
              {inlineUser && (
                <div className="layout-profile">
                  <button type="button" className="p-link layout-profile-button" onClick={onInlineUserClick}>
                    <img src="assets/layout/images/avatar.png" alt="roma-layout" />
                    <div className="layout-profile-userinfo">
                      <span className="layout-profile-name">Arlene Welch</span>
                      <span className="layout-profile-role">Design Ops</span>
                    </div>
                  </button>
                  <CSSTransition
                    classNames="p-toggleable-content"
                    timeout={inlineUserTimeout}
                    in={inlineUserMenuActive}
                    unmountOnExit
                  >
                    <ul
                      className={classNames('layout-profile-menu', {
                        'profile-menu-active': inlineUserMenuActive,
                      })}
                    >
                      <li>
                        <button type="button" className="p-link">
                          <i className="pi pi-fw pi-user"></i>
                          <span>Profile</span>
                        </button>
                      </li>
                      <li>
                        <button type="button" className="p-link">
                          <i className="pi pi-fw pi-cog"></i>
                          <span>Settings</span>
                        </button>
                      </li>
                      <li>
                        <button type="button" className="p-link">
                          <i className="pi pi-fw pi-envelope"></i>
                          <span>Messages</span>
                        </button>
                      </li>
                      <li>
                        <button type="button" className="p-link">
                          <i className="pi pi-fw pi-bell"></i>
                          <span>Notifications</span>
                        </button>
                      </li>
                    </ul>
                  </CSSTransition>
                </div>
              )}
              <AppMenu
                model={menu}
                onMenuItemClick={onMenuItemClick}
                onRootMenuItemClick={onRootMenuItemClick}
                layoutMode={layoutMode}
                active={menuActive}
                mobileMenuActive={staticMenuMobileActive}
              />
            </div>
            <div className="layout-main">
              <div className="layout-content">
                {routers.map((router, index) => (
                  <Route key={`router${index}`} path={router.path} exact render={() => router.component} />
                ))}
              </div>
            </div>
            <div className="layout-content-mask"></div>
          </React.Fragment>
        </>
      )}
    </div>
  );
};

export default App;
