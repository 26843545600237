import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { useEffect, useState } from 'react';
import licensesService from '../../services/ScaleoAdminApiServices/LicensesService';
import '../DataTable.css';
import { Dropdown } from 'primereact/dropdown';
import { CustomerCompanyName } from '../../types/customer';
import { Calendar } from 'primereact/calendar';
import { LicenseType, License, Limits } from '../../types/license';
import { MultiSelect } from 'primereact/multiselect';
import LicenseLimits from './components/Limits';
import { InputTextarea } from 'primereact/inputtextarea';

const INIT_FORM_STATE = {
  companyName: '',
  customerId: '',
  type: '',
  expirationDate: null,
  version: process.env.REACT_APP_SCALEO_OFFLINE_LATEST_VERSION,
  deviceKey: '',
  email: '',
  subscriptionModules: [] as string[],
  remarks: '',
  limits: {
    allowGenerateReports: true,
    allowApiAccess: true,
    useImagePosReceipts: false,
    allowEdgeDeviceConfigurationUpdate: true,
    usePolishBDOLanguage: false,
    turnOnHttpNodeAuth: true,
    turnOnAutomaticZeroing: true,
  } as Limits | undefined,
};

interface NewOrEditLicenseDialogProps {
  visible: boolean;
  closeDialog: (b: boolean) => void;
  item: License;
  customerCompanyNames: CustomerCompanyName[];
}

const LICENSE_TYPES = [
  {
    value: LicenseType.OFFLINE,
    label: 'Offline',
  },
  {
    value: LicenseType.ONLINE,
    label: 'Online',
  },
];

const NewOrEditLicenseDialog = ({ visible, closeDialog, item, customerCompanyNames }: NewOrEditLicenseDialogProps) => {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  useEffect(() => {
    if (visible && item?.id) {
      const {
        companyName,
        type,
        deviceKey,
        email,
        customerId,
        expirationDate,
        subscriptionModules,
        version,
        limits,
        remarks,
      } = item;
      setInitFormValues({
        companyName,
        type,
        deviceKey: deviceKey as string,
        version,
        email,
        customerId,
        expirationDate: expirationDate ? (new Date(expirationDate) as any) : expirationDate,
        subscriptionModules,
        limits,
        remarks: remarks || '',
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
  }, [item, visible]);

  const validateMethod = (data: any) => {
    const errors = {} as any;

    if (!data.customerId) {
      errors.companyName = 'Należy wybrać nazwę klienta.';
    }

    if (!data.type) {
      errors.type = 'Należy wybrać typ klienta.';
    }

    if (data.subscriptionModules && data.subscriptionModules.length < 1) {
      errors.subscriptionModules = 'Należy wybrać przynajmniej jeden moduł Scaleo.';
    }

    if (data.type === LicenseType.OFFLINE) {
      if (!data.version) {
        errors.version = 'Należy podać wersję aplikacji';
      } else {
        const versionRegex = /^\d+\.\d+\.\d+$/;
        if (!versionRegex.test(data.version)) {
          errors.version = 'Wersja musi być w formacie np. "4.26.6" (trzy grupy cyfr, przedzielone kropkami).';
        }
      }
    }

    return errors;
  };

  const handleCloseDialog = (changes: boolean) => {
    formik.resetForm({
      values: initFormValues,
    });
    closeDialog(changes === true);
  };

  const handleSubmit = (data: License) => {
    const body = data;

    if (item?.id) {
      void licensesService.editLicense(body, item.id).then(() => {
        handleCloseDialog(true);
      });
    } else {
      void licensesService.postLicense(body).then(() => {
        handleCloseDialog(true);
      });
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit as any,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label="Anuluj"
        icon="pi pi-times"
        className="p-button-text"
        onClick={handleCloseDialog as any}
      />
      <Button type="submit" label="Zapisz" icon="pi pi-check" className="p-button-text" onClick={formik.submitForm} />
    </>
  );

  const isFormFieldInvalid = (name: keyof typeof formik.touched) => formik.touched[name] && formik.errors[name];

  const getFormErrorMessage = (name: keyof typeof formik.touched) =>
    isFormFieldInvalid(name) && <Message severity="error" text={formik.errors[name] as any} />;

  const handleChangeField = (e: any, type: string) => {
    switch (type) {
      case 'text':
        void formik.setFieldValue(e.target.id, e.target.value, true);
        break;

      case 'dropdown':
        void formik.setFieldValue(e.target.id, e.value, false);
        break;

      default:
        void formik.handleChange(e);
        break;
    }
  };

  const onSubscriptionModulesChanged = (event: any) => {
    const subscriptionModuleIds = event.value;
    void formik.setFieldValue('subscriptionModules', subscriptionModuleIds);
  };

  const customerCompanyNamesMap = customerCompanyNames.map((c) => ({ value: c.id, label: c.companyName }));

  return (
    <div className="license-edit-dialog">
      <Dialog
        visible={visible}
        header="Szczegóły klienta"
        modal
        className="p-fluid"
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
        footer={dialogFooter}
        onHide={handleCloseDialog as any}
      >
        <form>
          <div className="p-col-10">
            <div className="p-field">
              <label htmlFor="companyName">Nazwa Firmy</label>
              <Dropdown
                id="companyName"
                value={customerCompanyNamesMap.find((c) => c.value === formik.values.customerId)?.value}
                options={customerCompanyNamesMap}
                onChange={(e) => formik.setFieldValue('customerId', e.value)}
                placeholder="Wybierz nazwę firmy"
              />
              {getFormErrorMessage('companyName')}
            </div>
            <div className="p-field">
              <label htmlFor="email">Email</label>
              <InputText id="email" value={formik.values.email} onChange={(e) => handleChangeField(e, 'text')} />
            </div>
            {formik.values.type === LicenseType.OFFLINE && (
              <div className="p-field">
                <label htmlFor="deviceKey">Identyfikator instalacji</label>
                <InputText
                  id="deviceKey"
                  value={formik.values.deviceKey}
                  onChange={(e) => handleChangeField(e, 'text')}
                />
              </div>
            )}
            <div className="p-field">
              <label htmlFor="license_type">Typ</label>
              <Dropdown
                id="type"
                value={formik.values.type}
                options={LICENSE_TYPES}
                onChange={formik.handleChange}
                placeholder="Wybierz typ"
              />
              {getFormErrorMessage('type')}
            </div>
            {formik.values.type === LicenseType.OFFLINE && (
              <div className="p-field">
                <label htmlFor="license_version">Wersja</label>
                <InputText
                  id="version"
                  value={formik.values.version}
                  placeholder="Podaj wersję"
                  onChange={(e) => handleChangeField(e, 'text')}
                />
                {getFormErrorMessage('version')}
              </div>
            )}
            <div className="p-field">
              <label htmlFor="expirationDate">Data wygaśnięcia</label>
              <Calendar
                id="time24"
                dateFormat="dd.mm.yy"
                value={formik.values.expirationDate as any}
                onChange={(e) => formik.setFieldValue('expirationDate', e.value)}
                showTime
                showSeconds
              />
            </div>
            <div className="p-field">
              <label htmlFor="role">Aktywne moduły</label>
              <MultiSelect
                value={formik.values.subscriptionModules}
                onChange={onSubscriptionModulesChanged}
                options={[
                  { id: 'BDO', name: 'BDO' },
                  { id: 'WEIGHING', name: 'Ważenie' },
                  { id: 'AUTOMATION', name: 'Automatyzacja' },
                  { id: 'CAMERAS', name: 'Kamery' },
                  { id: 'REGISTRATION_NUMBER_RECOGNITION', name: 'Rozpoznawanie numerów rejestracyjnych' },
                  { id: 'ACCESS_CONTROL', name: 'Kontrola dostępu' },
                  { id: 'RFID_SERVICE', name: 'Czytniki kart RFID' },
                  { id: 'ACCESS_CONTROL_ARRIVAL_NOTIFICATION', name: 'Awizacje' },
                  { id: 'CONTAINERS', name: 'Kontenery/opakowania' },
                  { id: 'VEHICLE_OVERLOAD_CONTROL', name: 'Kontrola przeciążenia pojazdu' },
                  { id: 'QR_ACCESS_CONTROL', name: 'Kontrola dostępu przez kod QR' },
                  { id: 'BALANCE_REPORTS', name: 'Raporty bilansowe' },
                  { id: 'ACCESS_CONTROL_ARRIVAL_NOTIFICATION_CALENDAR_VIEW', name: 'Kalendarz awizacji' },
                ]}
                optionLabel="name"
                optionValue="id"
                placeholder="Wybierz aktywne moduły Scaleo"
                className="multiselect-custom"
                maxSelectedLabels={0}
                selectedItemsLabel="Zaznaczono: {0}"
                showClear={true}
              />
              {getFormErrorMessage('subscriptionModules')}
            </div>
            <div className="p-field">
              <label htmlFor="remarks">Uwagi</label>
              <InputTextarea
                autoResize
                id="remarks"
                value={formik.values.remarks}
                onChange={(e) => handleChangeField(e, 'text')}
              />
            </div>
            <LicenseLimits formik={formik} />
          </div>
        </form>
      </Dialog>
    </div>
  );
};

export default NewOrEditLicenseDialog;
