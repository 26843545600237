import axios from 'axios';
import { AdminRoleDto, AdminRolesFilters } from '../../components/roles/Roles.types';
import { PaginationParams } from '../../types/general-types';

class AdminRolesService {
  async searchAdminRoles(paginatorParam: PaginationParams, filters: AdminRolesFilters | null = null) {
    const adminRoles = await axios.post('/admin-roles/search', { filters, paginatorParam });

    return adminRoles.data;
  }
  async createAdminRole(adminRoleData: AdminRoleDto) {
    return axios.post(`/admin-roles`, adminRoleData);
  }
  async editAdminRole(adminRoleData: AdminRoleDto, roleId: string) {
    return axios.put(`/admin-roles/${roleId}`, adminRoleData);
  }
  async deleteAdminRole(roleId: string) {
    return axios.delete(`/admin-roles/${roleId}`);
  }
  async deleteMultipleRoles(roleIds: string[]) {
    return axios.post(`/admin-roles/delete-multiple`, roleIds);
  }
}

const adminRolesService = new AdminRolesService();
export default adminRolesService;
