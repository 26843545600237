import { useState, useCallback } from 'react';

import customersService from '../../services/ScaleoAdminApiServices/CustomersService';

const useCustomerWithLicenseData = () => {
  const [data, setData] = useState([]);

  const fetchCustomerWithLicenseData = useCallback(async () => {
    const allCustomersWithLicenses = await customersService.getAllCustomersWithLicenses();

    if (allCustomersWithLicenses) {
      setData(allCustomersWithLicenses.data);
    }
  }, []);

  return {
    data,
    fetchCustomerWithLicenseData,
  };
};

export default useCustomerWithLicenseData;
