import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import getPaginatorTemplate from '../../_shared/getPaginatorTemplate';
import { Recognition, Result } from '../../../types/recognition';
import { useRef } from 'react';
import { RecognitionsTableHeader } from './RecognitionsTableHeader';
import { useColumnsTemplates } from '../../../hooks/Recognitions/useColumnsTemplates';
import { Station } from '../../../types/station';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';
import { Dropdown } from 'primereact/dropdown';

interface RecognitionsTableProps {
  recognitions: Recognition[];
  setResultsObject: (r: Result[] | null) => void;
  setActiveIndex: (n: number | null) => void;
  clearFilters: () => void;
  internalFilters: Record<string, string>;
  changeFilter: (value: any, name: string) => void;
  paginationParameters: any;
  handleSort: (e: any) => void;
  paginatorState: any;
  setPaginatorState: (a: any) => void;
  stations: Station[];
}

export const RecognitionsTable = ({
  setResultsObject,
  setActiveIndex,
  clearFilters,
  internalFilters,
  changeFilter,
  recognitions,
  paginationParameters,
  paginatorState,
  handleSort,
  setPaginatorState,
  stations,
}: RecognitionsTableProps) => {
  const dt = useRef(null);

  const {
    columnBodyTemplate,
    registrationNumberScoresTemplate,
    resultsBodyTemplate,
    imageTemplate,
    stationBodyTemplate,
  } = useColumnsTemplates(recognitions, setResultsObject, setActiveIndex, stations);

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                ref={dt}
                value={recognitions}
                header={
                  <RecognitionsTableHeader
                    changeFilter={changeFilter}
                    clearFilters={clearFilters}
                    internalFilters={internalFilters}
                  />
                }
                className="p-datatable-responsive"
                dataKey="id"
                rowHover
                emptyMessage={'Brak danych'}
                removableSort
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
              >
                <Column
                  className="cursor-pointer"
                  key={'createdAt'}
                  field={'createdAt'}
                  header={'Wykonano'}
                  body={columnBodyTemplate}
                  fieldsType={'datetime'}
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.CustomDatePicker
                      filters={internalFilters}
                      onChangeFilter={changeFilter}
                      name={'createdAt'}
                    />
                  }
                />
                <Column
                  field="station"
                  header={'Stanowisko'}
                  body={stationBodyTemplate}
                  filter
                  filterElement={
                    <Dropdown
                      value={internalFilters['stationId']}
                      options={stations}
                      onChange={(e) => changeFilter(e.value, 'stationId')}
                      placeholder={'Filtruj'}
                      className="p-column-filter"
                      showClear
                      optionLabel="name"
                      optionValue="id"
                    />
                  }
                />
                <Column field="photo" header={'Rozpoznane zdjęcie'} body={imageTemplate} />
                <Column
                  key={'registrationNumberScoresTemplate'}
                  field={'registrationNumberScoresTemplate'}
                  header={'Numery rejestracyjne'}
                  body={registrationNumberScoresTemplate}
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={internalFilters.registrationNumber}
                      onChangeFilter={(e: any) => changeFilter(e, 'registrationNumber')}
                      name="Numer rejestracyjny"
                      placeholder="Filtruj"
                    />
                  }
                />
                <Column key={'results'} field={'results'} header={'Odpowiedź ANPR'} body={resultsBodyTemplate} />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
