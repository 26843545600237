import React from 'react';
import { Button } from 'primereact/button';
import { useHistory } from 'react-router-dom';

export const Error = () => {
  const history = useHistory();

  return (
    <div className="exception-body error">
      <div className="exception-panel">
        <div className="exception-content">
          <img src="assets/layout/images/pages/icon-error.svg" alt="roma" />
          <h1>Błąd aplikacji</h1>
          <p>Coś poszło nie tak.</p>
          <Button
            label="Powrót na stronę główną"
            icon="pi pi-arrow-left"
            onClick={() => {
              history.push('/');
            }}
          />
        </div>
      </div>
    </div>
  );
};
