import { useFormik } from 'formik';
import { PaginationParams } from '../../types/general-types';
import adminUsersService from '../../services/ScaleoAdminApiServices/AdminUsersService';

const emptyAdminUser = {
  id: null,
  firstName: '',
  lastName: '',
  email: '',
  newPassword: false,
  password: undefined,
  confPassword: '',
  role: {
    id: '',
    name: '',
  },
};

const useAdminUsersForm = (
  fetchData: (paginationParameters: PaginationParams) => void,
  paginationParameters: PaginationParams,
) => {
  const formik = useFormik({
    initialValues: emptyAdminUser,
    validate: (data) => {
      const errors: any = {};

      if ((!data.id && !data.password) || (data.newPassword && !data.password)) {
        errors.password = 'Hasło jest wymagane';
      }
      if (data.password && data.password !== data.confPassword) {
        errors.confPassword = 'Potwierdzenie hasła jest wymagane';
      }
      if (!data.firstName) {
        errors.firstName = 'Imię jest wymagane';
      }
      if (!data.lastName) {
        errors.lastName = 'Nazwisko jest wymagane';
      }
      if (!data.role) {
        errors.role = 'Rola jest wymagana';
      }
      if (!data.email) {
        errors.email = 'Adres e-mail jest wymagany';
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      const newFormData = { ...formData, roleId: formData.role?.id };

      if (!newFormData.id) {
        adminUsersService
          .createAdminUser(newFormData)
          .then(() => {
            fetchData(paginationParameters);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('email', 'Taki administrator juz istnieje');
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        adminUsersService
          .editAdminUser(newFormData, newFormData.id)
          .then(() => {
            fetchData(paginationParameters);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', 'Taki administrator juz istnieje');
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const updateForm = (data: any = emptyAdminUser) => {
    formik.resetForm({ values: data });
  };

  return { formik, updateForm };
};

export default useAdminUsersForm;
