import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

interface DeleteStationsDialogProps {
  deleteStationsDialogVisible: boolean;
  hideDeleteStationsDialog: () => void;
  deleteSelectedStations: () => void;
}

export const DeleteStationsDialog = ({
  deleteStationsDialogVisible,
  hideDeleteStationsDialog,
  deleteSelectedStations,
}: DeleteStationsDialogProps) => {
  const deleteStationsDialogFooter = (
    <>
      <Button label={'Nie'} icon="pi pi-times" className="p-button-text" onClick={hideDeleteStationsDialog} />
      <Button label={'Tak'} icon="pi pi-check" className="p-button-text" onClick={deleteSelectedStations} />
    </>
  );

  return (
    <Dialog
      visible={deleteStationsDialogVisible}
      header={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {'Potwierdź'}
        </span>
      }
      modal
      className="p-fluid"
      footer={deleteStationsDialogFooter}
      onHide={hideDeleteStationsDialog}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
    >
      <div className="confirmation-content">{<span>{'Czy na pewno chcesz usunąć wybrane stanowiska?'}</span>}</div>
    </Dialog>
  );
};
