import { Button } from 'primereact/button';
import DataTableFilterElements from '../../_shared/DataTableFilterElements';

interface RecognitionsTableHeaderProps {
  clearFilters: () => void;
  internalFilters: Record<string, string>;
  changeFilter: (value: any, name: string) => void;
}

export const RecognitionsTableHeader = ({
  clearFilters,
  internalFilters,
  changeFilter,
}: RecognitionsTableHeaderProps) => (
  <div className="table-header">
    {'Rozpoznania'} &#160;&#160;
    <div className="p-text-right">
      <Button label={'Wyczyść'} icon="pi pi-filter-slash" className="p-button-outlined p-mr-2" onClick={clearFilters} />
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <DataTableFilterElements.Text
          initialValue={internalFilters['global']}
          onChangeFilter={changeFilter}
          name={'global'}
          placeholder={'Wyszukaj'}
        />
      </span>
    </div>
  </div>
);
