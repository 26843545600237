import { useFormik } from 'formik';
import { Station, StationType } from '../../types/station';
import stationsService from '../../services/ScaleoAdminApiServices/StationsService';

export const useStationForm = (
  station: Station | null,
  onStationAdded: ((s: Station) => void) | undefined,
  setStationDialogVisible: (b: boolean) => void,
) => {
  const formik = useFormik({
    initialValues: station ?? emptyStation,
    validate: (data) => {
      const errors = {} as any;

      if (!data.name) {
        errors.name = 'Nazwa nie może być pusta';
      }
      if (!data.locationId) {
        errors.location = 'Odział nie może być pusty';
      }
      if (!data.customerId) {
        errors.customer = 'Klient nie może być pusty';
      }

      return errors;
    },
    onSubmit: async (formData, helpers) => {
      if (!formData.id) {
        await stationsService
          .createStation(formData)
          .then((res) => {
            hideDialog();
            onStationAdded && onStationAdded(res.data);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', 'Stanowisko o tej nazwie już istnieje');
            }
          });
      } else {
        await stationsService
          .editStation(formData)
          .then((res) => {
            hideDialog();
            onStationAdded && onStationAdded(res.data);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', 'Stanowisko o tej nazwie już istnieje');
            }
          });
      }
    },
    enableReinitialize: true,
  });

  const hideDialog = () => {
    setStationDialogVisible(false);
    formik.resetForm({ values: emptyStation });
  };

  return {
    formik,
    hideDialog,
  };
};

const emptyStation = {
  id: null,
  name: '',
  installationId: '',
  locationId: '',
  customerId: '',
  type: StationType.WEIGHING_STATION,
} as Station;
