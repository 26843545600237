import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import locationsService from '../../services/ScaleoAdminApiServices/LocationsService';
import '../DataTable.css';
import { LocationsDeleteDialogProps } from '../../types/location';

const LocationsDeleteDialog = ({ closeLocationDeleteDialogHandler, location }: LocationsDeleteDialogProps) => {
  const hideLocationDeleteDialog = () => {
    closeLocationDeleteDialogHandler();
  };

  const handleConfirm = async () => {
    await locationsService.removeLocation(location.id);

    hideLocationDeleteDialog();
  };

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label="Anuluj"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideLocationDeleteDialog}
      />
      <Button type="button" label="Usuń" icon="pi pi-check" className="p-button-text" onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={true}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
      header={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          Potwierdź
        </span>
      }
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={hideLocationDeleteDialog}
    >
      {location ? (
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          <span>Czy na pewno chcesz usunąć oddział - {location.name}?</span>
        </div>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default LocationsDeleteDialog;
