import axios from 'axios';

export const authenticateUser = (enteredUsername, enteredPassword, callback) => {
  return axios
    .post('/auth/login', {
      username: enteredUsername,
      password: enteredPassword,
    })
    .then((res) => {
      callback(null, res.data.access_token, res.data.id, res.data.username);
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        callback({ code: 'NotAuthorizedException' }, null);
      } else {
        callback(error, null);
      }
    });
};

export const signOut = () => {
  //TODO: signOut
};
