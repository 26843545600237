import { useFormik } from 'formik';
import adminUsersService from '../../services/ScaleoAdminApiServices/AdminUsersService';
import { userActions } from '../../store/user-slice';
import { Dispatch } from 'redux';

const useUserProfileForm = (initialValues: any, loggedUserDetails: any, dispatch: Dispatch) => {
  const formik = useFormik({
    initialValues: initialValues,
    validate: (data) => {
      const errors = {} as Record<string, string>;
      if (!data.email) {
        errors.email = 'Niepoprawny e-mail';
      }
      if ((!data.id && !data.password) || (data.newPassword && !data.password)) {
        errors.password = 'Hasło nie może byc puste.';
      }
      if (data.password !== data.confPassword) {
        errors.confPassword = 'Hasła nie są takie same.';
      }
      if (!data.firstName) {
        errors.firstName = 'Imię nie może być puste.';
      }
      if (!data.lastName) {
        errors.lastName = 'Nazwisko nie może być puste.';
      }
      return errors;
    },
    onSubmit: async (formData) => {
      const { password, newPassword, confPassword, ...newForm } = formData;
      confPassword;

      if (newPassword) newForm.password = password;

      await adminUsersService.editAdminProfile(newForm, newForm.id);

      dispatch(
        userActions.setLoggedUserDetails({
          id: loggedUserDetails.id,
          firstName: newForm.firstName,
          lastName: newForm.lastName,
          email: newForm.email,
          permissions: loggedUserDetails.permissions,
        }),
      );

      window.location.reload();
      dispatch(userActions.shouldRefreshLoggedUserContext(true));
    },
    enableReinitialize: true,
  });

  return { formik };
};

export default useUserProfileForm;
