import axios from 'axios';

class DeploymentsService {
  async getAllDeployments() {
    return axios.get('/deployments');
  }
  async createDeployment(data) {
    return axios.post(`/deployments`, { data });
  }
  async publishDeployment(id) {
    return axios.put(`/deployments/publish/${id}`);
  }
  async deleteDeployment(id) {
    return axios.delete(`/deployments/${id}`);
  }
  async editDeployment(id, data) {
    return axios.put(`/deployments/${id}`, { data });
  }
  async finishDeployment(id) {
    return axios.put(`/deployments/finish/${id}`);
  }
}

const deploymentsService = new DeploymentsService();
export default deploymentsService;
