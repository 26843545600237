import { InputText } from 'primereact/inputtext';
import '../DataTable.css';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import { License } from '../../types/license';
import licensesService from '../../services/ScaleoAdminApiServices/LicensesService';
import { handleClickCopy } from '../../utils/copy';

interface GenerateLicenseKeyDialogProps {
  visible: boolean;
  closeDialog: (b: boolean) => void;
  license?: License;
}

const GenerateLicenseKeyDialog = ({ license, visible, closeDialog }: GenerateLicenseKeyDialogProps) => {
  const [mail, setMail] = useState('');
  const [deviceNumberHash, setDeviceNumberHash] = useState('');
  const [licenseKey, setLicenseKey] = useState<string | null>(null);
  const [isCopiedLicenseKey, setIsCopiedLicenseKey] = useState(false);

  useEffect(() => {
    setMail(license?.email ?? '');
    setDeviceNumberHash(license?.deviceKey ?? '');
    setLicenseKey(license?.licenseKey ?? null);
  }, [JSON.stringify(license)]);

  if (!license) {
    return <></>;
  }

  const generateLicenseKey = async () => {
    const licenseKeyResponse = await licensesService.generateLicenseKey(license.id, mail, deviceNumberHash);

    setLicenseKey(licenseKeyResponse.data);
  };

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label="Anuluj"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => closeDialog(true)}
      />
    </>
  );

  return (
    license && (
      <div className="customer-edit-dialog">
        <Dialog
          visible={visible}
          header="Szczegóły klienta"
          modal
          className="p-fluid"
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          style={{ width: '40vw' }}
          footer={dialogFooter}
          onHide={() => closeDialog(true)}
        >
          <div className="p-field">
            <label htmlFor="email">Email</label>
            <InputText id="email" value={mail} onChange={(e) => setMail(e.target.value)} />
          </div>
          <div className="p-field">
            <label htmlFor="deviceNumber">Identyfikator instalacji</label>
            <InputText
              id="deviceNumber"
              value={deviceNumberHash}
              onChange={(e) => setDeviceNumberHash(e.target.value)}
            />
          </div>
          <Button
            className="p-button-info my-split-button"
            onClick={() => generateLicenseKey()}
            disabled={!mail || !deviceNumberHash}
            style={{ width: '20vw', marginTop: '-0.5vh' }}
          >
            {' '}
            Generuj klucz licencyjny{' '}
          </Button>

          <div className="p-field" style={{ marginTop: '5vh' }}>
            <label htmlFor="licenseKey">Klucz licencyjny</label>
            <InputText id="licenseKey" value={licenseKey ?? ''} />
            <div style={{ display: 'inline-block' }}>
              <Button
                className="p-button-info my-split-button"
                style={{ width: '10vw', marginBottom: isCopiedLicenseKey ? '1vh' : '5vh', marginTop: '2vh' }}
                onClick={() => handleClickCopy(licenseKey as string, setIsCopiedLicenseKey)}
                disabled={isCopiedLicenseKey || !licenseKey}
              >
                Kopiuj
              </Button>
              {isCopiedLicenseKey && (
                <Message style={{ height: '4vh', width: '20vw' }} text={'Skopiowano do schowka'} />
              )}
            </div>
          </div>
        </Dialog>
      </div>
    )
  );
};

export default GenerateLicenseKeyDialog;
