import { Permission } from '../../enums/permission.enum';

export const getTranslatedPermissions = (permissionsId: string) => {
  switch (permissionsId) {
    case Permission.ADMIN_USERS_MANAGEMENT:
      return 'Zarządzanie administracją';
    case Permission.ROLES_MANAGEMENT:
      return 'Zarządzanie rolami';
    case Permission.ACCESS_TO_EVENT_LOG:
      return 'Dostęp do dziennika zdarzeń';
    case Permission.CUSTOMER_MANAGEMENT:
      return 'Zarządzanie klientami oraz licencjami';
    case Permission.SERVICE_USERS_MANAGEMENT:
      return 'Zarządzanie serwisantami';
    case Permission.SCALEO_USERS_MANAGEMENT:
      return 'Zarządzanie użytkownikami Scaleo';
    case Permission.NODE_MODELS_MANAGEMENT:
      return 'Zarządzanie konfiguracją urządzeń';
    case Permission.DEPLOYMENTS_MANAGEMENT:
      return 'Zarządzanie aktualizacjami';
    case Permission.STATIONS_MANAGEMENT:
      return 'Zarządzanie stacjami';
    case Permission.LOCATIONS_MANAGEMENT:
      return 'Zarządzanie oddziałami';
    case Permission.RECOGNITIONS:
      return 'Rozpoznania';
    default:
      return permissionsId;
  }
};
