import axios from 'axios';
import { AdminUserDto, Filters } from '../../components/adminUsers/AdminUsers.types';
import { PaginationParams } from '../../types/general-types';

class AdminUsersService {
  async searchAdminUsers(filters: Filters, paginatorParam: PaginationParams, globalFilter: string) {
    const adminUsers = await axios.post('/admin-users/search', { filters, paginatorParam, globalFilter });

    return adminUsers.data;
  }
  async createAdminUser(adminUserData: AdminUserDto) {
    return axios.post(`/admin-users`, adminUserData);
  }
  async editAdminUser(adminUserData: AdminUserDto, adminUserId: string) {
    return axios.put(`/admin-users/${adminUserId}`, adminUserData);
  }
  async editAdminProfile(adminUserData: AdminUserDto, adminUserId: string) {
    return axios.put(`/admin-users/edit-profile/${adminUserId}`, adminUserData);
  }
  async deleteAdminUser(adminUserId: string) {
    return axios.delete(`/admin-users/${adminUserId}`);
  }

  async getLoggedUserDetails() {
    return axios.get(`/admin-users/me/details`);
  }
}

const adminUsersService = new AdminUsersService();
export default adminUsersService;
