const socketCommandTemplate = [
  { id: 'a5997044-6d30-4e5f-8de4-7666fd1553c9', name: 'name', label: 'Nazwa' },
  { id: 'ccbce3e8-5684-4c18-8649-2a6a059cdb76', name: 'description', label: 'Opis' },
  { id: '56da4f82-12c1-4cfd-a27f-353ef7cfcef8', name: 'command', label: 'Komenda' },
  { id: 'a1d3088a-4de5-48e9-8c59-f5d067dae3b4', name: 'response_regex', label: 'Regex', rowsCount: 4 },
];

const restCommandTemplate = [
  { id: 'bff63dbb-f54f-4940-a9d4-93e7b9b343cf', name: 'name', label: 'Nazwa' },
  { id: 'c191b826-f6ba-4675-8bbe-d3b5cf35b65d', name: 'description', label: 'Opis' },
  {
    id: '1b344a53-13ad-47be-b7e4-c75b06a1477b',
    name: 'method',
    label: 'Metoda',
    dropdown: true,
    source: [{ name: 'GET' }, { name: 'POST' }, { name: 'PUT' }, { name: 'DELETE' }],
  },
  { id: '0cde3daa-0296-40fe-9d59-1f03a31cb2c7', name: 'url', label: 'URL' },
  { id: '9436a77e-9c40-4df4-812c-ad3773d4abf6', name: 'response_jsonpath', label: 'JSON Path', rowsCount: 4 },
];

export { socketCommandTemplate, restCommandTemplate };
