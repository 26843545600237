import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import licensesService from '../../services/ScaleoAdminApiServices/LicensesService';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import formatDateTime from '../_shared/formatDateTime';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import { License, LicenseType, LicenseVersion } from '../../types/license';
import GenerateLicenseKeyDialog from './GenerateLicenseKeyDialog';
import DeleteLicenseDialog from './DeleteLicenseDialog';
import NewOrEditLicenseDialog from './NewOrEditLicenseDialog';
import customersService from '../../services/ScaleoAdminApiServices/CustomersService';
import { CustomerCompanyName } from '../../types/customer';

const INIT_FILTERS = {
  customerId: null,
};

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: false,
    orderColumn: 'expirationDate',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const Licenses = () => {
  const dt = useRef(null);
  const [filters, setFilters] = useState<any>(INIT_FILTERS);
  const [paginationParameters, setPaginationParameters] = useState(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(INIT_PAGINATOR_STATE);
  const [isOpenNewOrEditDialog, setIsOpenNewOrEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [isOpenGenerateKeyDialog, setIsOpenGenerateKeyDialog] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState<License[] | null>(null);
  const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<License[] | License | null>(null);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<License | null>(null);
  const [selectedRowToGenerateKey, setSelectedRowToGenerateKey] = useState<License | null>(null);
  const [customerCompanyNames, setCustomerCompanyNames] = useState<CustomerCompanyName[]>([]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const refreshListData = useCallback(() => {
    Promise.all([
      licensesService.searchLicenses(filters, paginationParameters),
      customersService.getCustomerCompanyNames(),
    ])
      .then((response: any) => {
        setListData(response[0].data.items);
        setPaginatorState({
          ...paginatorState,
          totalPages: response[0].data.totalPagesNumber,
          currentPage: paginatorState.currentPage > response[0].data.totalPagesNumber ? 1 : paginatorState.currentPage,
        });
        setCustomerCompanyNames(response[1].data);
      })
      .catch(() => {
        setListData([]);
      });
  }, [filters, paginationParameters]);

  useEffect(() => {
    refreshListData();
  }, [refreshListData]);

  const handleClickClearFilters = () => {
    setFilters({});
  };

  const header = (
    <div className="table-header">
      Lista licencji &#160;&#160;
      <Button
        label="Wyczyść filtry"
        icon="pi pi-filter-slash"
        className="p-button-outlined p-mr-2"
        onClick={handleClickClearFilters}
      />
    </div>
  );

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setSelectedRowToEdit(null);
      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteLicense = () => {
      setSelectedRowsToDelete(selectedRows);
      setIsOpenDeleteDialog(true);
    };

    return (
      <>
        <Button label="Dodaj" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={handleClickNew} />
        <Button
          label="Usuń"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={handleClickDeleteLicense}
          disabled={!selectedRows || !selectedRows.length}
        />
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return <></>;
  };

  const actionBodyTemplate = (rowData: License) => {
    const handleClickEditLicenseRow = () => {
      setSelectedRowToEdit(rowData);
      setSelectedRows([rowData]);
      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteLicenseRow = () => {
      setSelectedRowsToDelete(rowData);
      setSelectedRows([rowData]);
      setIsOpenDeleteDialog(true);
    };

    const handleClickGenerateKeyLicenseRow = () => {
      setSelectedRowToGenerateKey(rowData);
      setSelectedRows([rowData]);
      setIsOpenGenerateKeyDialog(true);
    };

    return (
      <div className="actions" style={{ width: '9vw', marginLeft: '-3vw' }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={handleClickEditLicenseRow}
        />
        {rowData.type === 'OFFLINE' && (
          <Button
            icon="pi pi-key"
            className="p-button-rounded p-button-error p-mr-2"
            onClick={handleClickGenerateKeyLicenseRow}
          />
        )}
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={handleClickDeleteLicenseRow}
        />
      </div>
    );
  };

  const licenseKeyBodyTemplate = (rowData: License) => {
    return <>{rowData.licenseKey ? rowData.licenseKey?.slice(0, 8) + '...' : ''}</>;
  };

  const deviceKeyBodyTemplate = (rowData: License) => {
    return <>{rowData.deviceKey ? rowData.deviceKey?.slice(0, 12) + '...' : ''}</>;
  };

  const expirationDateBodyTemplate = (rowData: License) => {
    return rowData.expirationDate ? formatDateTime(rowData.expirationDate) : '-';
  };

  const handleCloseNewOrEditDialog = (changes: boolean) => {
    setIsOpenNewOrEditDialog(false);
    if (changes) refreshListData();
  };

  const handleCloseDeleteDialog = (changes: boolean) => {
    setIsOpenDeleteDialog(false);
    if (changes) refreshListData();
  };

  const handleCloseGenerateKeyDialog = (changes: boolean) => {
    setIsOpenGenerateKeyDialog(false);
    if (changes) refreshListData();
  };

  const handleChangeFilter = (value: string, name: string) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    setFilters(newFilters);
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const handleSelectionChange = () => {
    const selected = [] as License[];
    setSelectedRows(selected);
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <NewOrEditLicenseDialog
            visible={isOpenNewOrEditDialog}
            closeDialog={handleCloseNewOrEditDialog}
            item={selectedRowToEdit as License}
            customerCompanyNames={customerCompanyNames}
          />
          <DeleteLicenseDialog
            visible={isOpenDeleteDialog}
            closeDialog={handleCloseDeleteDialog}
            selectedRows={selectedRowsToDelete as License | License[]}
          />
          <GenerateLicenseKeyDialog
            visible={isOpenGenerateKeyDialog}
            closeDialog={handleCloseGenerateKeyDialog}
            license={selectedRowToGenerateKey as License}
          />
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                ref={dt}
                value={listData}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                removableSort
                rowHover
                rows={paginatorState.rows}
                paginator
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                emptyMessage="Brak rekordów"
                onSort={handleSort}
                selection={selectedRows}
                onSelectionChange={handleSelectionChange}
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column
                  field="companyName"
                  header="Nazwa Firmy"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.companyName}
                      onChangeFilter={handleChangeFilter}
                      name="companyName"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column
                  body={deviceKeyBodyTemplate}
                  field="deviceKey"
                  header="Identyfikator instalacji"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.deviceKey}
                      onChangeFilter={handleChangeFilter}
                      name="deviceKey"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column
                  body={licenseKeyBodyTemplate}
                  field="licenseKey"
                  header="Klucz licencyjny"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.licenseKey}
                      onChangeFilter={handleChangeFilter}
                      name="licenseKey"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column
                  field="email"
                  header="Email"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.email}
                      onChangeFilter={handleChangeFilter}
                      name="email"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column
                  field="version"
                  header="Wersja"
                  sortable
                  filter
                  filterElement={
                    <Dropdown
                      value={filters.version}
                      options={Object.values(LicenseVersion)}
                      onChange={(e) => handleChangeFilter(e.value, 'version')}
                      placeholder={'Filtruj'}
                      className="p-column-filter"
                      showClear
                    />
                  }
                ></Column>
                <Column
                  field="type"
                  header="Typ"
                  sortable
                  filter
                  filterField="type"
                  filterElement={
                    <Dropdown
                      value={filters.type}
                      options={Object.values(LicenseType)}
                      onChange={(e) => handleChangeFilter(e.value, 'type')}
                      placeholder={'Filtruj'}
                      className="p-column-filter"
                      showClear
                    />
                  }
                ></Column>
                <Column
                  body={expirationDateBodyTemplate}
                  field="expirationDate"
                  header="Data wygasnięcia"
                  sortable
                ></Column>
                <Column body={actionBodyTemplate} style={{ width: '100px', textAlign: 'center' }}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Licenses;
