import { useFormik } from 'formik';
import adminRolesService from '../../services/ScaleoAdminApiServices/AdminRolesService';
import { PaginationParams } from '../../types/general-types';

const emptyRole = {
  id: null,
  name: '',
  permissions: [],
};

const useRoleForm = (
  fetchData: (paginationParameters: PaginationParams) => void,
  paginationParameters: PaginationParams,
) => {
  const formik = useFormik({
    initialValues: emptyRole,
    validate: (data) => {
      const errors: any = {};

      if (!data.name) {
        errors.name = 'Brak nazwy';
      }

      return errors;
    },
    onSubmit: async (formData) => {
      const permissionIds: string[] = formData.permissions?.map((permission: any) => permission.id);

      const parsedFormData = {
        ...formData,
        permissions: permissionIds,
      };

      if (!parsedFormData.id) {
        await adminRolesService.createAdminRole(parsedFormData);

        fetchData(paginationParameters);
      } else {
        await adminRolesService.editAdminRole(parsedFormData, parsedFormData.id);

        fetchData(paginationParameters);
      }
    },
    enableReinitialize: true,
  });

  const resetForm = (data: any = emptyRole) => {
    formik.resetForm({ values: data });
  };

  return { formik, resetForm };
};

export default useRoleForm;
