import { Sidebar } from 'primereact/sidebar';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';
import { uiActions } from '../../store/ui-slice';

const Loader = () => {
  const dispatch = useDispatch();
  const isLoaderVisible = useSelector((state) => state.ui.isLoading);
  const toast = useRef();

  useEffect(() => {
    let loaderTimer;
    if (isLoaderVisible) {
      loaderTimer = setTimeout(() => {
        toast.current.show({
          severity: 'error',
          summary: 'Błąd ładowania',
          detail: 'Wystąpił błąd, przekroczono czas oczekiwania. Spróbuj ponownie.',
          life: 4000,
        });
        dispatch(uiActions.toggleIsLoading({ isLoading: false }));
      }, 20000);
    } else {
      clearTimeout(loaderTimer);
    }
    return () => clearTimeout(loaderTimer);
  }, [isLoaderVisible, dispatch]);

  return (
    <>
      <Sidebar visible={isLoaderVisible} baseZIndex={1000} fullScreen showCloseIcon={false}>
        <ul className="loader">
          <li className="dot-a"></li>
          <li className="dot-b"></li>
          <li className="dot-c"></li>
          <li className="dot-d"></li>
          <li className="dot-e"></li>
          <li className="dot-f"></li>
          <li className="dot-g"></li>
          <li className="dot-h"></li>
          <li className="dot-i"></li>
        </ul>
      </Sidebar>
      <Toast ref={toast} />
    </>
  );
};

export default Loader;
