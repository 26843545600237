export enum ScaleoUserType {
  INTERNAL_USER = 'INTERNAL_USER',
  EXTERNAL_USER = 'EXTERNAL_USER',
  SERVICE_USER = 'SERVICE_USER',
  SYSTEM_USER = 'SYSTEM_USER',
}

export type ScaleoUser = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  type: ScaleoUserType;
  customerId: string;
};
