import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
  name: 'ui',
  initialState: { isLoading: false },
  reducers: {
    toggleIsLoading(state, value) {
      state.isLoading = value.payload.isLoading;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;
