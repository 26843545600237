import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useEffect } from 'react';
import '../DataTable.css';
import { Checkbox } from 'primereact/checkbox';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { CustomAutoComplete } from '../_shared/CustomComponents';
import FormErrorMessage from '../_shared/FormErrorMessage';
import { LocationDialogProps } from '../../types/location';
import useCustomerWithLicenseData from '../../hooks/customers/useCustomerWithLicenseData';

const LocationDialog = ({ closeDialogHandler, locationData, formik, updateForm }: LocationDialogProps) => {
  const hideDialog = () => {
    updateForm();
    closeDialogHandler();
  };

  const { data: customersWithLicenses, fetchCustomerWithLicenseData } = useCustomerWithLicenseData();

  useEffect(() => {
    void fetchCustomerWithLicenseData();

    if (locationData?.id) {
      updateForm({
        id: locationData.id,
        name: locationData.name,
        shortName: locationData.shortName,
        city: locationData.city,
        street: locationData.street,
        postalCode: locationData.postalCode,
        customer: {
          name: locationData.customer?.companyName,
          value: locationData.customer?.id,
        },
        isTestWeighing: locationData.isTestWeighing,
      });
    } else {
      updateForm();
    }
  }, []);

  const dialogFooter = (
    <>
      <Button type="reset" label="Anuluj" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button
        type="submit"
        label="Zapisz"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          formik.submitForm().then(() => {
            if (!Object.keys(formik.errors).length) {
              closeDialogHandler();
            }
          });
        }}
      />
    </>
  );

  const handleCustomerChange = (e: any) => {
    formik.setFieldValue('customer', e?.value);
  };

  const handleShortNameChange = (e: any) => {
    formik.setFieldValue('shortName', e.target.value?.toUpperCase());
  };

  return (
    <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
      <div className="location-edit-dialog">
        <Dialog
          visible={true}
          header="Szczegóły oddziału"
          modal
          className="p-fluid"
          breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
          style={{ width: '40vw' }}
          footer={dialogFooter}
          onHide={hideDialog}
        >
          <form>
            <div className="p-col-10">
              <div className="p-field">
                <label htmlFor="name">Nazwa</label>
                <InputText id="name" value={formik.values.name} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="name" formikInstance={formik} />
              </div>
              <div className="p-field">
                <label htmlFor="shortName">Skrócona nazwa</label>
                <InputText id="shortName" value={formik.values.shortName} onChange={handleShortNameChange} />
                <FormErrorMessage fieldName="shortName" formikInstance={formik} />
              </div>

              <div className="p-field">
                <label htmlFor="city">Miejscowość</label>
                <InputText id="city" value={formik.values.city} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="city" formikInstance={formik} />
              </div>

              <div className="p-field">
                <label htmlFor="street">Ulica, numer budynku i mieszkania</label>
                <InputText id="street" value={formik.values.street} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="street" formikInstance={formik} />
              </div>

              <div className="p-field">
                <label htmlFor="postalCode">Kod pocztowy</label>
                <InputText id="postalCode" value={formik.values.postalCode} onChange={formik.handleChange} />
                <FormErrorMessage fieldName="postalCode" formikInstance={formik} />
              </div>

              <div className="p-field">
                <label htmlFor="customer">{'Klient'}</label>
                <CustomAutoComplete
                  id="customer"
                  value={formik.values.customer?.name ? formik.values.customer?.name : formik.values.customer}
                  handler={handleCustomerChange}
                  source={customersWithLicenses.map((customerWithLicense: any) => ({
                    name: customerWithLicense.companyName,
                    value: customerWithLicense.id,
                  }))}
                  lookupField={'name'}
                  inputStyle={undefined}
                  customSearchBranch={undefined}
                  customItemTemplate={undefined}
                  placeholder={'Wybierz'}
                  scrollHeight={undefined}
                  tooltip={undefined}
                  disabled={formik.values.id}
                  forceSelection={undefined}
                  onBlur={undefined}
                />
                <FormErrorMessage fieldName="customer" formikInstance={formik} />
              </div>

              <div className="p-field-checkbox">
                <Checkbox id="isTestWeighing" checked={formik.values.isTestWeighing} onChange={formik.handleChange} />
                <label htmlFor="isTestWeighing" className="p-checkbox-label">
                  Włącz tryb ważeń testowych
                </label>
              </div>
            </div>
          </form>
        </Dialog>
      </div>
    </FormErrorMessageScroller>
  );
};

export default LocationDialog;
