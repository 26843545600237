import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Fieldset } from 'primereact/fieldset';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { Message } from 'primereact/message';
import useUserProfileForm from '../../hooks/userProfile/useUserProfileForm';

const UserProfile = () => {
  const dispatch = useDispatch();
  const loggedUserDetails = useSelector((state: any) => state.user.details);

  const [initFormValues] = useState({
    id: loggedUserDetails.id,
    firstName: loggedUserDetails.firstName,
    lastName: loggedUserDetails.lastName,
    email: loggedUserDetails.email,
    newPassword: false,
    password: '',
    confPassword: '',
  });

  const { formik } = useUserProfileForm(initFormValues, loggedUserDetails, dispatch);

  return (
    <div className="card" style={{ paddingBottom: '70px' }}>
      <FormErrorMessageScroller formikInstance={formik} beforeScroll={undefined}>
        <form onSubmit={formik.handleSubmit}>
          <Fieldset legend={'Profil'}>
            <div className="p-fluid p-formgrid p-grid">
              <div className="p-col-12 p-md-6 p-lg-4">
                {(!formik.values.firstName || !formik.values.lastName) && (
                  <Message
                    style={{ height: '4vh', width: '20vw', marginBottom: '2vh' }}
                    text={'Musisz dodać imię i nazwisko'}
                  />
                )}
                <div className="p-field">
                  <label htmlFor="email">{'Email'}</label>
                  <InputText id="email" value={formik.values.email} readOnly disabled />
                </div>
                <div className="p-field">
                  <div className="p-field-checkbox">
                    <Checkbox id="newPassword" checked={formik.values.newPassword} onChange={formik.handleChange} />
                    <label htmlFor="newPassword">{'Nowe hasło'}</label>
                  </div>
                </div>
                {formik.values.newPassword && (
                  <>
                    <div className="p-field">
                      <label htmlFor="password">{'Hasło'}</label>
                      <Password
                        id="password"
                        name="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        feedback={false}
                        toggleMask
                      />
                      <FormErrorMessage fieldName="password" formikInstance={formik} />
                    </div>
                    <div className="p-field">
                      <label htmlFor="confPassword">{'Potwierdź hasło'}</label>
                      <Password
                        id="confPassword"
                        name="confPassword"
                        value={formik.values.confPassword}
                        onChange={formik.handleChange}
                        feedback={false}
                      />
                      <FormErrorMessage fieldName="confPassword" formikInstance={formik} />
                    </div>
                    <hr />
                  </>
                )}
                <div className="p-field">
                  <label htmlFor="firstName">{'Imię'}</label>
                  <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
                  <FormErrorMessage fieldName="firstName" formikInstance={formik} />
                </div>
                <div className="p-field">
                  <label htmlFor="lastName">{'Nazwisko'}</label>
                  <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
                  <FormErrorMessage fieldName="lastName" formikInstance={formik} />
                </div>
              </div>
            </div>
            <div className="action-buttons">
              <div className="p-d-flex p-flex-column p-flex-md-row p-jc-end p-ai-end">
                <Button type="submit" label={'Zapisz'} icon="pi pi-check" className="p-button-text" />
              </div>
            </div>
          </Fieldset>
        </form>
      </FormErrorMessageScroller>
    </div>
  );
};

export default UserProfile;
