import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Station } from '../../../types/station';

interface DeleteStationDialogProps {
  deleteStationDialogVisible: boolean;
  hideDeleteStationDialog: () => void;
  station: Station;
  deleteStation: () => void;
}

export const DeleteStationDialog = ({
  deleteStationDialogVisible,
  hideDeleteStationDialog,
  station,
  deleteStation,
}: DeleteStationDialogProps) => {
  const deleteStationDialogFooter = (
    <>
      <Button label={'Nie'} icon="pi pi-times" className="p-button-text" onClick={hideDeleteStationDialog} />
      <Button label={'Tak'} icon="pi pi-check" className="p-button-text" onClick={deleteStation} />
    </>
  );

  return (
    <Dialog
      visible={deleteStationDialogVisible}
      header={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {'Potwierdź'}
        </span>
      }
      modal
      className="p-fluid"
      footer={deleteStationDialogFooter}
      onHide={hideDeleteStationDialog}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
    >
      <div className="confirmation-content">{station && <span>{'Czy na pewno chcesz usunąć stacje'}</span>}</div>
    </Dialog>
  );
};
