import React, { useState, useEffect, useCallback } from 'react';
import { Filters } from '../../components/adminUsers/AdminUsers.types';
import usePagination from '../_shared/usePagination';
import adminUsersService from '../../services/ScaleoAdminApiServices/AdminUsersService';
import adminRolesService from '../../services/ScaleoAdminApiServices/AdminRolesService';
import { AdminRole } from '../../components/roles/Roles.types';
import { PaginationParams } from '../../types/general-types';

const useAdminData = (initialFilters: Filters, setAdminRoles: React.Dispatch<React.SetStateAction<AdminRole[]>>) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const fetchData = useCallback(
    async (paginationParameters: PaginationParams) => {
      const adminUsersResponse = await adminUsersService.searchAdminUsers(initialFilters, paginationParameters, '');

      if (adminUsersResponse) {
        setData(adminUsersResponse.data);
        setTotalPages(adminUsersResponse.totalPages);
      }
    },
    [initialFilters],
  );

  const { paginationParameters, setPaginationParameters, paginatorState, setPaginatorState } = usePagination(fetchData);

  useEffect(() => {
    void adminRolesService.searchAdminRoles(paginationParameters).then((response: any) => {
      if (response.data) {
        setAdminRoles(response.data.map((adminRole: AdminRole) => ({ id: adminRole.id, name: adminRole.name })));
      }
    });

    setPaginatorState((prevState) => ({ ...prevState, totalPages }));
  }, [totalPages]);

  return { data, paginatorState, setPaginatorState, paginationParameters, setPaginationParameters, fetchData };
};

export default useAdminData;
