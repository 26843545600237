import axios from 'axios';
import { Customer } from '../../types/customer';

class CustomersService {
  async searchCustomers(filters?: any, paginationParameters?: any, globalFilter?: any) {
    return axios.post('/customers/search', { filters, paginationParameters, globalFilter });
  }
  async postCustomer(data: Customer) {
    return axios.post(`/customers`, data);
  }
  async editCustomer(data: Customer, id: string) {
    return axios.put(`/customers/${id}`, data);
  }
  async removeCustomer(id: string) {
    return axios.delete(`/customers/${id}`);
  }
  async getCustomerCompanyNames() {
    return axios.get('/customers/company-names');
  }

  async getAllCustomersWithLicenses() {
    return axios.get(`/customers/all-with-licenses`);
  }

  async getOnlineCustomersWithLicenses() {
    return axios.get(`/customers/online-with-licenses`);
  }

  async checkTaxIdExistence(id: string, customerId: string | null) {
    const returnValue = await axios.get(`/customers/check-tax-id/${id}/${customerId}`);

    return returnValue.data;
  }
}
const customersService = new CustomersService();
export default customersService;
