import Amplify from '@aws-amplify/core';

export const configureApp = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,
    },
    AppSync: {
      dangerouslyConnectToHTTPEndpointForTesting: true,
    },
  });
  Amplify.Logger.LOG_LEVEL = 'INFO';
};
