import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { PickList } from 'primereact/picklist';
import { RolesModalProps } from './Roles.types';

const RolesModal = ({ availablePermissions, setAvailablePermissions, formik, closeModalHandler }: RolesModalProps) => {
  const itemTemplate = (item: any) => {
    return <div className="product-item">{item.name}</div>;
  };
  const onChange = (event: any) => {
    setAvailablePermissions(event.source);
    formik.setFieldValue('permissions', event.target);
  };

  const roleDialogFooter = (
    <>
      <Button
        type="reset"
        label="Anuluj"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => closeModalHandler()}
      />
      <Button
        type="submit"
        label="Zapisz"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => {
          formik.submitForm().then(() => {
            if (!Object.keys(formik.errors).length) {
              closeModalHandler();
            }
          });
        }}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={true}
        header={'Szczegóły roli'}
        modal
        className="p-fluid"
        footer={roleDialogFooter}
        onHide={() => closeModalHandler()}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="p-col-10">
            <div className="p-field">
              <label htmlFor="name">{'Nazwa'}</label>
              <InputText id="name" value={formik.values.name} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="name" formikInstance={formik} />
              <br />
            </div>
            <div className="p-field">
              <PickList
                source={availablePermissions}
                target={formik.values.permissions}
                itemTemplate={itemTemplate}
                sourceHeader={'Dostępne uprawnienia'}
                targetHeader={'Wybrane uprawnienia'}
                sourceStyle={{ height: '262px' }}
                targetStyle={{ height: '262px' }}
                onChange={onChange}
                showSourceControls={false}
                showTargetControls={false}
              ></PickList>
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default RolesModal;
