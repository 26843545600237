import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import nodeModelService from '../../services/ScaleoAdminApiServices/NodeModelsService';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import DeleteNodeModelDialog from './DeleteNodeModelDialog';
import NewOrEditNodeModelDialog from './NewOrEditNodeModelDialog';

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: true,
    orderColumn: 'name',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const NodeModels = () => {
  const [paginationParameters, setPaginationParameters] = useState(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(INIT_PAGINATOR_STATE);
  const [nodeModels, setNodeModels] = useState(null);

  const [selectedRowToEdit, setSelectedRowToEdit] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [selectedRowsToDelete, setSelectedRowsToDelete] = useState(null);

  const [isOpenNewOrEditDialog, setIsOpenNewOrEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const dt = useRef(null);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const refreshListData = useCallback(() => {
    nodeModelService
      .searchNodeModels(paginationParameters)
      .then((response) => {
        setNodeModels(response.data.items);
        setPaginatorState({
          ...paginatorState,
          totalPages: response.data.totalPagesNumber,
          currentPage: paginatorState.currentPage > response.data.totalPagesNumber ? 1 : paginatorState.currentPage,
        });
      })
      .catch(() => {
        setNodeModels([]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationParameters]);

  useEffect(() => {
    refreshListData();
  }, [refreshListData]);

  const handleSort = (e) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const header = <div className="table-header">Lista obsługiwanych systemów wagowych</div>;

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setSelectedRowToEdit(null);
      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteCustomer = () => {
      setSelectedRowsToDelete(selectedRows);
      setIsOpenDeleteDialog(true);
    };

    return (
      <>
        <Button label="Dodaj" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={handleClickNew} />
        <Button
          label="Usuń"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={handleClickDeleteCustomer}
          disabled={!selectedRows || !selectedRows.length}
        />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    const handleClickEditRow = () => {
      setSelectedRowToEdit(rowData);
      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteRow = () => {
      setSelectedRowsToDelete([rowData]);
      setIsOpenDeleteDialog(true);
    };

    return (
      <div className="actions">
        <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={handleClickEditRow} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning" onClick={handleClickDeleteRow} />
      </div>
    );
  };

  const handleCloseNewOrEditDialog = (changes) => {
    setIsOpenNewOrEditDialog(false);
    if (changes) refreshListData();
  };

  const handleCloseDeleteDialog = (changes) => {
    setIsOpenDeleteDialog(false);
    if (changes) {
      setSelectedRowsToDelete([]);
      refreshListData();
    }
  };

  const nameBodyTemplate = (rowData) => {
    return <>{rowData.name}</>;
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                ref={dt}
                value={nodeModels}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                removableSort
                rowHover
                rows={paginatorState.rows}
                selection={selectedRows}
                selectionMode="checkbox"
                onSelectionChange={(e) => setSelectedRows(e.value)}
                paginator
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
              >
                <Column selectionMode="multiple" cellSelection headerStyle={{ width: '3rem' }}></Column>
                <Column field="name" header="Nazwa" body={nameBodyTemplate} sortable></Column>
                <Column body={actionBodyTemplate} style={{ width: '100px', textAlign: 'center' }}></Column>
              </DataTable>
            </div>
          </div>
          {isOpenNewOrEditDialog && (
            <NewOrEditNodeModelDialog
              visible={isOpenNewOrEditDialog}
              closeDialog={handleCloseNewOrEditDialog}
              item={selectedRowToEdit}
            />
          )}
          {isOpenDeleteDialog && (
            <DeleteNodeModelDialog
              visible={isOpenDeleteDialog}
              closeDialog={handleCloseDeleteDialog}
              selectedRows={selectedRowsToDelete}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NodeModels;
