import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import deploymentsService from '../../../services/ScaleoAdminApiServices/DeploymentsService';

const DeleteDeploymentDialog = ({ visible, closeDialog, item }) => {
  const handleCloseDialog = (changes) => {
    closeDialog(changes === true);
  };

  const handleConfirm = async () => {
    await deploymentsService.deleteDeployment(item.id);
    handleCloseDialog(true);
  };

  const dialogFooter = (
    <>
      <Button type="reset" label="Anuluj" icon="pi pi-times" className="p-button-text" onClick={handleCloseDialog} />
      <Button type="button" label="Usuń" icon="pi pi-check" className="p-button-text" onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
      header={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          Potwierdź
        </span>
      }
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={handleCloseDialog}
    >
      <div className="confirmation-content">
        <span>
          Czy na pewno chcesz usunąć{' '}
          {item?.version ? (
            <>
              aktualizację do wersji <b>{item.version}</b>
            </>
          ) : (
            'wybraną aktualizcję'
          )}{' '}
          ?
        </span>
      </div>
    </Dialog>
  );
};

export default DeleteDeploymentDialog;
