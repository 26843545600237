import axios from 'axios';

class RecognitionsService {
  async searchRecognitions(filters: any, paginatorParam: any) {
    return await axios.post(`/recognitions/search`, {
      filters,
      paginatorParam,
    });
  }
}

const recognitionsService = new RecognitionsService();
export default recognitionsService;
