import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState } from 'react';
import '../../DataTable.css';
import deploymentsService from '../../../services/ScaleoAdminApiServices/DeploymentsService';
import FormErrorMessageScroller from '../../_shared/FormErrorMessageScroller';
import FormErrorMessage from '../../_shared/FormErrorMessage';

const INIT_FORM_STATE = {
  version: '',
  startDate: '',
  endDate: '',
  description: '',
};

const NewOrEditDeploymentDialog = ({ visible, closeDialog, item }) => {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  useEffect(() => {
    if (visible && item?.id) {
      setInitFormValues({
        version: item.version,
        startDate: new Date(item.startDate),
        endDate: new Date(item.endDate),
        description: item.description,
      });
    } else {
      setInitFormValues(INIT_FORM_STATE);
    }
  }, [visible, item]);

  const validateMethod = (data) => {
    const errors = {};

    if (!data.startDate) {
      errors.startDate = 'Podaj datę początkową';
    }
    if (!data.endDate) {
      errors.endDate = 'Podaj datę końcową';
    }

    return errors;
  };

  const handleCloseDialog = (changes) => {
    formik.resetForm({
      values: initFormValues,
    });
    closeDialog(changes === true);
  };

  const handleSubmit = (data) => {
    if (item?.id) {
      void deploymentsService.editDeployment(item?.id, data).then(() => {
        handleCloseDialog(true);
      });
    } else {
      void deploymentsService.createDeployment(data).then(() => {
        handleCloseDialog(true);
      });
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const dialogFooter = (
    <>
      <Button type="reset" label="Anuluj" icon="pi pi-times" className="p-button-text" onClick={handleCloseDialog} />
      <Button type="submit" label="Zapisz" icon="pi pi-check" className="p-button-text" onClick={formik.submitForm} />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header="Szczegóły aktualizacji"
        modal
        className="p-fluid"
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
        footer={dialogFooter}
        onHide={handleCloseDialog}
      >
        <form>
          <div className="p-col-12">
            <div className="p-field">
              <label htmlFor="version">Wersja</label>
              <InputText id="version" value={formik.values.version} onChange={formik.handleChange} />
            </div>
            <div className="p-grid">
              <div className="p-col-6 p-field">
                <label htmlFor="start_date">Data od </label>
                <Calendar
                  id="startDate"
                  dateFormat="dd.mm.yy"
                  value={formik.values.startDate}
                  onChange={(e) => formik.setFieldValue('startDate', e.value)}
                  showTime
                  autoFocus
                  disabled={!!item?.id}
                  maxDate={formik.values.endDate || null}
                />
                <FormErrorMessage fieldName="startDate" formikInstance={formik} />
              </div>
              <div className="p-col-6 p-field">
                <label htmlFor="start_date">Data do </label>
                <Calendar
                  id="endDate"
                  dateFormat="dd.mm.yy"
                  value={formik.values.endDate}
                  onChange={(e) => formik.setFieldValue('endDate', e.value)}
                  showTime
                  disabled={!!item?.id}
                  minDate={formik.values.startDate || null}
                />
                <FormErrorMessage fieldName="endDate" formikInstance={formik} />
              </div>
            </div>
            <div className="p-field">
              <label htmlFor="description">Informacja dla użytkowników systemu Scaleo</label>
              <InputTextarea
                id="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                rows={5}
              />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default NewOrEditDeploymentDialog;
