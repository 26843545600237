import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

interface RolesModalProps {
  role?: any;
  selectedRoles?: any;
  deleteRoleHandler: () => void;
  closeModalHandler: () => void;
  isSingleDelete: boolean;
}

const RolesDeleteModal = ({
  role = null,
  selectedRoles = null,
  deleteRoleHandler,
  closeModalHandler,
  isSingleDelete,
}: RolesModalProps) => {
  const dialogFooter = (
    <>
      <Button label="Nie" icon="pi pi-times" className="p-button-text" onClick={() => closeModalHandler()} />
      <Button label="Tak" icon="pi pi-check" className="p-button-text" onClick={() => deleteRoleHandler()} />
    </>
  );

  return (
    <>
      <Dialog
        visible={true}
        className="p-fluid"
        header={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            {isSingleDelete ? 'Potwierdź usunięcie roli' : 'Potwierdź usunięcie ról'}
          </span>
        }
        modal
        footer={dialogFooter}
        onHide={() => closeModalHandler()}
        breakpoints={{ '896px': '90vw' }}
        style={{ minWidth: '450px' }}
      >
        <div className="confirmation-content">
          {role && isSingleDelete && (
            <span>
              Usuń rolę: <strong>{role.name}</strong>
            </span>
          )}
          {selectedRoles && (
            <span>
              Usuń wybrane role:{' '}
              <strong>{selectedRoles.map((selectedRole: any) => selectedRole.name).join(', ')}</strong>
            </span>
          )}
        </div>
      </Dialog>
    </>
  );
};

export default RolesDeleteModal;
