import axios from 'axios';
import { ScaleoUser } from '../../types/scaleo-user';

class CustomerUsersService {
  async searchCustomerUsers(filters: any, paginatorParam: any, globalFilter?: string) {
    return axios.post('/customer-users/search', { filters, paginatorParam, globalFilter });
  }
  async inviteCustomerUser(user: Partial<ScaleoUser>) {
    return axios.post('/customer-users/invitation', user);
  }
  async resendInvitationCustomerUser(params: any) {
    return axios.post('/customer-users/resend-invitation', params);
  }
  async editCustomerUser(user: Partial<ScaleoUser>, id: string) {
    return axios.put(`/customer-users/${id}`, user);
  }
  async deleleCustomerUser(id: string) {
    return axios.delete(`/customer-users/${id}`);
  }
}
const customerUsersService = new CustomerUsersService();
export default customerUsersService;
