import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: { details: {} },
  reducers: {
    setLoggedUser(state, value) {
      state.loggedUser = value.payload;
    },
    setLoggedUserDetails(state, value) {
      state.details = value.payload;
    },
    shouldRefreshLoggedUserContext(state, value) {
      state.shouldRefreshLoggedUserContext = value.payload;
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
