import axios from 'axios';
import { Station } from '../../types/station';

class StationsService {
  async searchStations(filters: any, paginatorParam: any) {
    const stations = await axios.post(`/stations/search`, {
      filters,
      paginatorParam,
    });

    return { data: stations.data, totalPages: (stations as any).totalPages };
  }

  async getStations() {
    return axios.get(`/stations`);
  }

  async createStation(station: Station) {
    return axios.post(`/stations`, station);
  }

  async editStation(station: Station) {
    return axios.put(`/stations`, station);
  }

  async removeManyStations(stations: Station[]) {
    return axios.post(
      `/stations/remove-many`,
      stations.map((v) => v.id),
    );
  }

  async generateAnprApiKey(id: string) {
    return axios.post(`/stations/generate-anpr-api-key`, {
      id,
    });
  }
}

const stationsService = new StationsService();
export default stationsService;
