import axios from 'axios';

class PermissionsService {
  async getAllPermissions() {
    return axios.get('/permissions');
  }
}

const permissionsService = new PermissionsService();
export default permissionsService;
