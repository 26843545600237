import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';

interface LimitsProps {
  formik: any;
}

const Limits = ({ formik }: LimitsProps) => (
  <>
    <div className="p-field">
      <label htmlFor="role">Maksymalna liczba użytkowników</label>
      <InputNumber
        id="maximumUsersNumber"
        value={formik.values.limits?.maximumUsersNumber}
        onChange={(e) => formik.setFieldValue('limits', { ...formik.values.limits, maximumUsersNumber: e.value })}
        mode="decimal"
        minFractionDigits={0}
        maxFractionDigits={5}
        min={0}
      />
    </div>
    <div className="p-field">
      <label htmlFor="role">Maksymalna liczba jednocześnie zalogowanych użytkowników</label>
      <InputNumber
        id="usersNumber"
        value={formik.values.limits?.usersNumber}
        onChange={(e) => formik.setFieldValue('limits', { ...formik.values.limits, usersNumber: e.value })}
        mode="decimal"
        minFractionDigits={0}
        maxFractionDigits={5}
        min={0}
      />
    </div>
    <div className="p-field">
      <label htmlFor="role">Maksymalna liczba użytkowników zewnętrznych</label>
      <InputNumber
        id="maximumExternalUsersNumber"
        value={formik.values.limits?.maximumExternalUsersNumber}
        onChange={(e) =>
          formik.setFieldValue('limits', { ...formik.values.limits, maximumExternalUsersNumber: e.value })
        }
        mode="decimal"
        minFractionDigits={0}
        maxFractionDigits={5}
        min={0}
      />
    </div>
    <div className="p-field">
      <label htmlFor="role">Liczba wag</label>
      <InputNumber
        id="scalesNumber"
        value={formik.values.limits?.scalesNumber}
        onChange={(e) => formik.setFieldValue('limits', { ...formik.values.limits, scalesNumber: e.value })}
        mode="decimal"
        minFractionDigits={0}
        maxFractionDigits={5}
        min={0}
      />
    </div>
    <div className="p-field">
      <label htmlFor="role">Liczba oddziałów</label>
      <InputNumber
        id="locationsNumber"
        value={formik.values.limits?.locationsNumber}
        onChange={(e) => formik.setFieldValue('limits', { ...formik.values.limits, locationsNumber: e.value })}
        mode="decimal"
        minFractionDigits={0}
        maxFractionDigits={5}
        min={0}
      />
    </div>
    <div className="p-field">
      <label htmlFor="role">Liczba miesięcznych pomiarów</label>
      <InputNumber
        id="maximumMeasurementsNumber"
        value={formik.values.limits?.maximumMeasurementsNumber}
        onChange={(e) =>
          formik.setFieldValue('limits', { ...formik.values.limits, maximumMeasurementsNumber: e.value })
        }
        mode="decimal"
        minFractionDigits={0}
        maxFractionDigits={5}
        min={0}
      />
    </div>
    <div className="p-field">
      <label htmlFor="role">Liczba pól dodatkowych</label>
      <InputNumber
        id="additionalFieldsNumber"
        value={formik.values.limits?.additionalFieldsNumber}
        onChange={(e) => formik.setFieldValue('limits', { ...formik.values.limits, additionalFieldsNumber: e.value })}
        mode="decimal"
        minFractionDigits={0}
        maxFractionDigits={5}
        min={0}
      />
    </div>
    <div className="p-field-checkbox">
      <Checkbox
        id="allowGenerateReports"
        checked={formik.values.limits?.allowGenerateReports}
        onChange={() =>
          formik.setFieldValue('limits', {
            ...formik.values.limits,
            allowGenerateReports: !formik.values.limits?.allowGenerateReports,
          })
        }
      />
      <label htmlFor="role">Zezwól na generowanie raportów</label>
    </div>
    <div className="p-field-checkbox">
      <Checkbox
        id="addWatermark"
        checked={formik.values.limits?.addWatermark}
        onChange={() =>
          formik.setFieldValue('limits', { ...formik.values.limits, addWatermark: !formik.values.limits?.addWatermark })
        }
      />
      <label htmlFor="role">Dodaj znak wodny scaleo do kwitów wagowych</label>
    </div>
    <div className="p-field-checkbox">
      <Checkbox
        id="allowApiAccess"
        checked={formik.values.limits?.allowApiAccess}
        onChange={() =>
          formik.setFieldValue('limits', {
            ...formik.values.limits,
            allowApiAccess: !formik.values.limits?.allowApiAccess,
          })
        }
      />
      <label htmlFor="role">Pozwalaj na dostęp przez klucz API</label>
    </div>
    <div className="p-field-checkbox">
      <Checkbox
        id="useImagePosReceipts"
        checked={
          formik.values.limits?.useImagePosReceipts ||
          formik.values.limits?.useImagePosReceipts === null ||
          formik.values.limits?.useImagePosReceipts === undefined
        }
        onChange={() =>
          formik.setFieldValue('limits', {
            ...formik.values.limits,
            useImagePosReceipts: !formik.values.limits?.useImagePosReceipts,
          })
        }
      />
      <label htmlFor="role">Używaj obrazów przy drukowaniu paragonów</label>
    </div>
    <div className="p-field-checkbox">
      <Checkbox
        id="allowEdgeDeviceConfigurationUpdate"
        checked={formik.values.limits?.allowEdgeDeviceConfigurationUpdate}
        onChange={() =>
          formik.setFieldValue('limits', {
            ...formik.values.limits,
            allowEdgeDeviceConfigurationUpdate: !formik.values.limits?.allowEdgeDeviceConfigurationUpdate,
          })
        }
      />
      <label htmlFor="role">Zezwól na synchronizację konfiguracji hosta</label>
    </div>

    <div className="p-field-checkbox">
      <Checkbox
        id="usePolishBDOLanguage"
        checked={formik.values.limits?.usePolishBDOLanguage}
        onChange={() =>
          formik.setFieldValue('limits', {
            ...formik.values.limits,
            usePolishBDOLanguage: !formik.values.limits?.usePolishBDOLanguage,
          })
        }
      />
      <label htmlFor="role">Używaj języka Polski BDO</label>
    </div>

    <div className="p-field-checkbox">
      <Checkbox
        id="turnOnHttpNodeAuth"
        checked={formik.values.limits?.turnOnHttpNodeAuth}
        onChange={() =>
          formik.setFieldValue('limits', {
            ...formik.values.limits,
            turnOnHttpNodeAuth: !formik.values.limits?.turnOnHttpNodeAuth,
          })
        }
      />
      <label htmlFor="role">Włącz authentykację elementów systemu wagowego z http</label>
    </div>

    <div className="p-field-checkbox">
      <Checkbox
        id="turnOnAutomaticZeroing"
        checked={formik.values.limits?.turnOnAutomaticZeroing}
        onChange={() =>
          formik.setFieldValue('limits', {
            ...formik.values.limits,
            turnOnAutomaticZeroing: !formik.values.limits?.turnOnAutomaticZeroing,
          })
        }
      />
      <label htmlFor="role">Włącz opcję automatycznego zerowanie elementów systemu wagowego</label>
    </div>
  </>
);

export default Limits;
