import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import customersService from '../../services/ScaleoAdminApiServices/CustomersService';
import customerUsersService from '../../services/ScaleoAdminApiServices/CustomerUsersService';
import '../DataTable.css';
import { CustomTableField } from '../_shared/CustomComponents';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import formatDateTime from '../_shared/formatDateTime';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import InviteOrEditScaleoUserDialog from './InviteOrEditScaleoUserDialog';
import DeleteUserDialog from './DeleteScaleoUserDialog';
import { Customer } from '../../types/customer';
import { User, UserActivationStatus } from '../../types/user';

const DataTableAny = DataTable as any;

const EMPTY_USER = {
  customerId: null,
  email: '',
  id: null,
  firstName: '',
  lastName: '',
};

export const ScaleoUsers = () => {
  const emptyFilters = {
    global: null,
    customerId: null,
    email: null,
    firstName: null,
    lastName: null,
  };

  const emptyPaginationParameters = {
    order: {
      isAscending: false,
      orderColumn: 'createdAt',
    },
    page: {
      index: 1,
      size: 10,
    },
  };

  const emptyPaginatorState = {
    currentPage: 1,
    totalPages: 1,
    rows: 10,
  };

  const sortCustomers = (customers: Customer[]) => {
    return customers.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  };

  const [user, setUser] = useState<User | null>(null);
  const [users, setUsers] = useState(null);
  const [userDialogVisible, setUserDialogVisible] = useState(false);
  const [deleteUserDialogVisible, setDeleteUserDialogVisible] = useState(false);
  const [filters, setFilters] = useState<any>(emptyFilters);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);
  const [customers, setCustomers] = useState<any[]>();
  const [customerFilter, setCustomerFilter] = useState();

  const dt = useRef(null);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const fetchComponentData = useCallback(
    async (filters: any, paginatorParam: any) => {
      void Promise.all([
        customerUsersService.searchCustomerUsers(filters, paginatorParam),
        customersService.searchCustomers(),
      ]).then((response) => {
        setUsers(response[0].data.data);
        setCustomers(
          sortCustomers(
            response[1].data.items?.map((customer: Customer) => ({ id: customer.id, name: customer.companyName })),
          ),
        );

        response[0].data.totalPages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response[0].data.totalPages,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });
      });
    },
    [paginatorState],
  );

  useEffect(() => {
    void fetchComponentData(filters, paginationParameters);
  }, [filters, paginationParameters, userDialogVisible]);

  const inviteNew = () => {
    setUser(EMPTY_USER as any);
    setUserDialogVisible(true);
  };

  const resendInvitation = (user: User) => {
    void customerUsersService.resendInvitationCustomerUser({ userId: user.id }).then(() => {
      void fetchComponentData(filters, paginationParameters);
    });
  };

  const editUser = (_user: User) => {
    setUser(_user);
    setUserDialogVisible(true);
  };

  const confirmDeleteUser = (user: User) => {
    setUser(user);
    setDeleteUserDialogVisible(true);
  };

  const deleteUser = () => {
    if (!user) {
      return;
    }
    void customerUsersService.deleleCustomerUser(user.id).then(() => {
      void fetchComponentData(filters, paginationParameters);
      setDeleteUserDialogVisible(false);
      setUser(EMPTY_USER as any);
    });
  };

  const changeFilter = (value: any, name: string) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    setFilters(newFilters);
  };

  const handleChangeCustomerFilter = (value: any) => {
    setCustomerFilter(value);
    changeFilter(value, 'customerId');
  };

  const clearFilters = () => {
    setFilters({});
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const header = (
    <div className="table-header">
      Lista użytkowników &#160;&#160;
      <div className="p-text-right">
        <Button
          label="Wyczyść filtry"
          icon="pi pi-filter-slash"
          className="p-button-outlined p-mr-2"
          onClick={clearFilters}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={changeFilter}
            name={'global'}
            placeholder="Szukaj"
          />
        </span>
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label="Dodaj" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={inviteNew} />
      </>
    );
  };
  const rightToolbarTemplate = () => {
    return <></>;
  };

  const actionBodyTemplate = (rowData: any) => {
    const options = [
      { label: 'Edytuj', value: 'edit', icon: 'pi pi-pencil' },
      { label: 'Usuń', value: 'delete', icon: 'pi pi-trash' },
    ];

    rowData.status === 'INVITATION_SENT' &&
      options.push({ label: 'Wyślij nowe zaproszenie', value: 'resend-invitation', icon: 'pi pi-envelope' });

    const handleChangeOption = (e: any) => {
      switch (e.value) {
        case 'edit':
          editUser(rowData);
          break;
        case 'delete':
          confirmDeleteUser(rowData);
          break;
        case 'resend-invitation':
          resendInvitation(rowData);
          break;

        default:
          break;
      }
    };

    const dropdownOptionTemplate = (option: any) => {
      return (
        <div className="item">
          <i className={option.icon}></i>&#160;
          {option.label}
        </div>
      );
    };

    return (
      <div className="actions">
        <Dropdown
          options={options}
          onChange={handleChangeOption}
          placeholder="Opcje"
          itemTemplate={dropdownOptionTemplate}
        />
      </div>
    );
  };

  const statusBodyTemplate = (rowData: User) => {
    return (
      <React.Fragment>
        {rowData.status === UserActivationStatus.ACTIVE && (
          <Tag className="p-mr-2" icon="pi pi-check" severity="success" value="Aktywny"></Tag>
        )}
        {rowData.status === UserActivationStatus.INVITATION_SENT && (
          <Tag className="p-mr-2" icon="pi pi-info-circle" severity="info" value="Wysłano zaproszenie"></Tag>
        )}
        {rowData.status === UserActivationStatus.PENDING_CONFIRMATION && (
          <Tag className="p-mr-2" icon="pi pi-info-circle" severity="info" value="Niepotwierdzona rejestracja"></Tag>
        )}
      </React.Fragment>
    );
  };

  const customerBodyTemplate = (rowData: any) => {
    return rowData.customer.name;
  };

  const createdAtBodyTemplate = (rowData: User) => {
    return formatDateTime(rowData.createdAt);
  };

  const columnBodyTemplate = (rowData: User, column: string) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  const columns = [
    { field: 'email', header: 'Adres e-mail', type: 'text' },
    { field: 'firstName', header: 'Imie', type: 'text' },
    { field: 'lastName', header: 'Nazwisko', type: 'text' },
  ];

  const dynamicColumns = columns.map((col) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate as any}
        fieldsType={col.type}
        sortable
        filter
        filterElement={
          <DataTableFilterElements.Text
            initialValue={filters[col.field]}
            onChangeFilter={changeFilter}
            name={col.field}
            placeholder="Filtruj"
          />
        }
      ></Column>
    );
  });

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTableAny
                ref={dt}
                value={users}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                rowHover
                removableSort
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState)}
                emptyMessage="Brak użytkowników"
              >
                {dynamicColumns}
                <Column
                  field="customer.name"
                  header="Klient"
                  sortable
                  body={customerBodyTemplate}
                  filter
                  filterField="customer.name"
                  filterElement={
                    <Dropdown
                      value={customerFilter}
                      options={customers}
                      optionLabel="name"
                      optionValue="id"
                      onChange={(event) => handleChangeCustomerFilter(event.value)}
                      placeholder={'Filtruj'}
                      className="p-column-filter"
                      showClear
                    />
                  }
                ></Column>
                <Column body={statusBodyTemplate} header="Status" className="status"></Column>
                <Column body={createdAtBodyTemplate} field="createdAt" header="Data utworzenia" sortable></Column>
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTableAny>
              <InviteOrEditScaleoUserDialog
                visible={userDialogVisible}
                setVisible={setUserDialogVisible}
                user={user}
                customers={customers}
              />
              <DeleteUserDialog
                deleteUserDialogVisible={deleteUserDialogVisible}
                setDeleteUserDialogVisible={setDeleteUserDialogVisible}
                user={user}
                deleteUser={deleteUser}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScaleoUsers;
