import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import customersService from '../../services/ScaleoAdminApiServices/CustomersService';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import formatDateTime from '../_shared/formatDateTime';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import DeleteCustomerDialog from './DeleteCustomerDialog';
import NewOrEditCustomerDialog from './NewOrEditCustomerDialog';
import { Customer } from '../../types/customer';

const INIT_FILTERS = {
  companyName: null,
  firstName: null,
  lastName: null,
};

const INIT_PAGINATION_PARAMETERS = {
  order: {
    isAscending: false,
    orderColumn: 'createdAt',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const Customers = () => {
  const dt = useRef(null);
  const [filters, setFilters] = useState<any>(INIT_FILTERS);
  const [paginationParameters, setPaginationParameters] = useState(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState(INIT_PAGINATOR_STATE);
  const [isOpenNewOrEditDialog, setIsOpenNewOrEditDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [listData, setListData] = useState([]);
  const [selectedRows, setSelectedRows] = useState<Customer[] | null>(null);
  const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<Customer[] | Customer | null>(null);
  const [selectedRowToEdit, setSelectedRowToEdit] = useState<Customer | null>(null);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const refreshListData = useCallback(() => {
    customersService
      .searchCustomers(filters, paginationParameters)
      .then((response) => {
        setListData(response.data.items);
        setPaginatorState({
          ...paginatorState,
          totalPages: response.data.totalPagesNumber,
          currentPage: paginatorState.currentPage > response.data.totalPagesNumber ? 1 : paginatorState.currentPage,
        });
      })
      .catch(() => {
        setListData([]);
      });
  }, [filters, paginationParameters]);

  useEffect(() => {
    refreshListData();
  }, [refreshListData]);

  const handleClickClearFilters = () => {
    setFilters({
      companyName: null,
      firstName: null,
      lastName: null,
    });
  };

  const header = (
    <div className="table-header">
      Lista klientów &#160;&#160;
      <Button
        label="Wyczyść filtry"
        icon="pi pi-filter-slash"
        className="p-button-outlined p-mr-2"
        onClick={handleClickClearFilters}
      />
    </div>
  );

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setSelectedRowToEdit(null);
      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteCustomer = () => {
      setSelectedRowsToDelete(selectedRows);
      setIsOpenDeleteDialog(true);
    };

    return (
      <>
        <Button label="Dodaj" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={handleClickNew} />
        <Button
          label="Usuń"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={handleClickDeleteCustomer}
          disabled={!selectedRows || !selectedRows.length}
        />
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return <></>;
  };

  const actionBodyTemplate = (rowData: Customer) => {
    const handleClickEditCustomerRow = () => {
      setSelectedRowToEdit(rowData);
      setSelectedRows([rowData]);
      setIsOpenNewOrEditDialog(true);
    };

    const handleClickDeleteCustomerRow = () => {
      setSelectedRowsToDelete(rowData);
      setSelectedRows([rowData]);
      setIsOpenDeleteDialog(true);
    };

    return (
      <div className="actions" style={{ width: '9vw', marginLeft: '-3vw' }}>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={handleClickEditCustomerRow}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={handleClickDeleteCustomerRow}
        />
      </div>
    );
  };

  const createdAtBodyTemplate = (rowData: Customer) => {
    return formatDateTime(rowData.createdAt);
  };

  const handleCloseNewOrEditDialog = (changes: boolean) => {
    setIsOpenNewOrEditDialog(false);
    if (changes) refreshListData();
  };

  const handleCloseDeleteDialog = (changes: boolean) => {
    setIsOpenDeleteDialog(false);
    if (changes) refreshListData();
  };

  const handleChangeFilter = (value: string, name: string) => {
    const newFilters = { ...filters };
    newFilters[name] = value;
    setFilters(newFilters);
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const handleSelectionChange = () => {
    const selected = [] as Customer[];
    setSelectedRows(selected);
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          {isOpenNewOrEditDialog && (
            <NewOrEditCustomerDialog closeDialog={handleCloseNewOrEditDialog} item={selectedRowToEdit} />
          )}
          <DeleteCustomerDialog
            visible={isOpenDeleteDialog}
            closeDialog={handleCloseDeleteDialog}
            selectedRows={selectedRowsToDelete as Customer | Customer[]}
          />
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                ref={dt}
                value={listData}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                removableSort
                rowHover
                rows={paginatorState.rows}
                paginator
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                emptyMessage="Brak rekordów"
                onSort={handleSort}
                selection={selectedRows}
                onSelectionChange={handleSelectionChange}
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                <Column
                  field="companyName"
                  header="Nazwa Firmy"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.companyName}
                      onChangeFilter={handleChangeFilter}
                      name="companyName"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>

                <Column
                  field="firstName"
                  header="Imię"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.firstName}
                      onChangeFilter={handleChangeFilter}
                      name="firstName"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column
                  field="lastName"
                  header="Nazwisko"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.lastName}
                      onChangeFilter={handleChangeFilter}
                      name="lastName"
                      placeholder="Filtruj"
                    />
                  }
                ></Column>
                <Column body={createdAtBodyTemplate} field="createdAt" header="Data utworzenia" sortable></Column>
                <Column field="remarks" header="Uwagi" style={{ width: '15%' }}></Column>
                <Column body={actionBodyTemplate} style={{ width: '100px', textAlign: 'center' }}></Column>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
