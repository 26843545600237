import { useState, useEffect, useCallback } from 'react';
import usePagination from '../_shared/usePagination';
import adminRolesService from '../../services/ScaleoAdminApiServices/AdminRolesService';
import permissionsService from '../../services/ScaleoAdminApiServices/PermissionsService';
import { AdminRolesFilters } from '../../components/roles/Roles.types';
import { getTranslatedPermissions } from '../../components/roles/permissions.translations';
import { PaginationParams } from '../../types/general-types';

const useRoleData = (initialFilters: AdminRolesFilters) => {
  const [data, setData] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  const fetchData = useCallback(
    async (paginationParameters: PaginationParams) => {
      const adminRolesResponse = await adminRolesService.searchAdminRoles(paginationParameters, initialFilters);

      if (adminRolesResponse) {
        const translatedData = adminRolesResponse.data.map((role: any) => ({
          ...role,
          permissions: role.permissions?.map((permissionId: string) => ({
            id: permissionId,
            name: getTranslatedPermissions(permissionId),
          })),
        }));

        setData(translatedData);

        setTotalPages(adminRolesResponse.totalPages);
      }
    },
    [initialFilters],
  );

  const { paginationParameters, setPaginationParameters, paginatorState, setPaginatorState } = usePagination(fetchData);

  useEffect(() => {
    void permissionsService.getAllPermissions().then((response: any) => {
      if (response.data) {
        setPermissions(
          response.data.map((element: any) => ({ id: element.id, name: getTranslatedPermissions(element.id) })),
        );
      }
    });

    setPaginatorState((prevState) => ({ ...prevState, totalPages }));
  }, [totalPages]);

  return {
    data,
    permissions,
    paginatorState,
    setPaginatorState,
    paginationParameters,
    setPaginationParameters,
    fetchData,
  };
};

export default useRoleData;
