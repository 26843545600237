import { signOut as logoutUserWithAWSCognito } from './AwsCognitoAuthService';
import {
  authenticateUser as authenticateUserWithScaleoBackend,
  signOut as logoutUserWithScaleoBackend,
} from './ScaleoBackendAuthService';
import { configureApp } from './ScaleoOfflineAppConfigurer';
import { configureApp as configureAppWithAwsAMplify } from './ScaleoOnlineAppConfigurer';

export const scaleoModeContext = () => {
  return process.env.REACT_APP_SCALEO_MODE === 'ONLINE'
    ? {
        // SCALEO ONLINE
        appConfigurer: configureAppWithAwsAMplify,
        authenticateUserHandler: authenticateUserWithScaleoBackend,
        logoutHandler: logoutUserWithAWSCognito,
      }
    : {
        // SCALEO OFFLINE
        appConfigurer: configureApp,
        authenticateUserHandler: authenticateUserWithScaleoBackend,
        logoutHandler: logoutUserWithScaleoBackend,
      };
};
