import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableSortParams } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import customersService from '../../services/ScaleoAdminApiServices/CustomersService';
import serviceUsersService from '../../services/ScaleoAdminApiServices/ServiceUsersService';
import '../DataTable.css';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import formatDateTime from '../_shared/formatDateTime';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import InviteOrEditServiceUserDialog from './InviteOrEditServiceUserDialog';
import DeleteServiceUserDialog from './DeleteServiceUserDialog';
import { SERVICE_USER_STATUS } from '../../enums/service-user-status.enum';
import { PaginationParams, PaginatorState } from '../../types/general-types';
import { CurrentRow, Filters, ServiceUser } from './ServiceUsersService.types';
import { Tooltip } from 'primereact/tooltip';

const INIT_PAGINATION_PARAMETERS: PaginationParams = {
  order: {
    isAscending: false,
    orderColumn: 'createdAt',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const INIT_PAGINATOR_STATE: PaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};

const emptyFilters: Filters = {
  global: null,
  firstName: null,
  lastName: null,
  email: null,
};

const ServiceUsers = () => {
  const dt = useRef(null);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState<Filters>(emptyFilters);
  const [paginationParameters, setPaginationParameters] = useState<PaginationParams>(INIT_PAGINATION_PARAMETERS);
  const [paginatorState, setPaginatorState] = useState<PaginatorState>(INIT_PAGINATOR_STATE);
  const [customers, setCustomers] = useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState<CurrentRow>(null);
  const [isNewOrEditDialogOpen, setIsNewOrEditDialogOpen] = useState(false);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const fetchComponentData = useCallback(async () => {
    const serviceUsersResponse = await serviceUsersService.searchServiceUsers(filters, paginationParameters, '');

    if (!serviceUsersResponse) return;

    const onlineCustomersWithLicenses = await customersService.getOnlineCustomersWithLicenses();

    if (!onlineCustomersWithLicenses) return;

    const allLocations = await serviceUsersService.getAllLocations();

    const customersWithLocations = onlineCustomersWithLicenses.data.map((customer: any) => {
      const customerLocations = allLocations.filter((location: any) => location.customer.id === customer.id);
      const locationsNumber = customerLocations.length;
      return { ...customer, locationsNumber };
    });

    setUsers(serviceUsersResponse.data);
    setCustomers(customersWithLocations);

    setPaginatorState({
      ...paginatorState,
      totalPages: serviceUsersResponse.totalPages,
      currentPage: paginatorState.currentPage > serviceUsersResponse.totalPages ? 1 : paginatorState.currentPage,
    });
  }, [filters, paginationParameters]);

  useEffect(() => {
    void fetchComponentData();
  }, [fetchComponentData]);

  const hideInviteOrEditDialog = () => {
    setIsNewOrEditDialogOpen(false);
  };

  const deleteUser = () => {
    if (!currentRow) {
      return;
    }
    void serviceUsersService.deleteServiceUser(currentRow.id).then(() => {
      setIsDeleteDialogOpen(false);
      void fetchComponentData();
    });
  };

  const createdAtBodyTemplate = (rowData: ServiceUser) => {
    return formatDateTime(rowData.createdAt);
  };

  const handleChangeFilter = (value: string, name: string) => {
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
  };

  const handleSort = (e: DataTableSortParams) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const clearFilters = () => {
    setFilters(emptyFilters);
  };

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setCurrentRow(null);
      setIsNewOrEditDialogOpen(true);
    };

    return (
      <>
        <Button label="Dodaj" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={handleClickNew} />
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return <></>;
  };

  const header = (
    <div className="table-header">
      Lista użytkowników serwisowych &#160;&#160;
      <div className="p-text-right">
        <Button
          label="Wyczyść filtry"
          icon="pi pi-filter-slash"
          className="p-button-outlined p-mr-2"
          onClick={clearFilters}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={handleChangeFilter}
            name={'global'}
            placeholder="Szukaj"
          />
        </span>
      </div>
    </div>
  );

  const statusBodyTemplate = (rowData: ServiceUser) => {
    return (
      <React.Fragment>
        {rowData.status === SERVICE_USER_STATUS.ACTIVE && (
          <Tag className="p-mr-2" icon="pi pi-check" severity="success" value="Aktywny" />
        )}
        {rowData.status === SERVICE_USER_STATUS.INVITATION_SENT && (
          <Tag className="p-mr-2" icon="pi pi-info-circle" severity="info" value="Wysłano zaproszenie" />
        )}
        {rowData.status === SERVICE_USER_STATUS.PENDING_CONFIRMATION && (
          <Tag className="p-mr-2" icon="pi pi-info-circle" severity="info" value="Niepotwierdzona rejestracja" />
        )}
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData: ServiceUser) => {
    const handleClickResendInvitation = async () => {
      await serviceUsersService.resendInvitationServiceUser({ userId: rowData.id });
    };

    const handleClickEditServiceUserRow = () => {
      setCurrentRow(rowData);
      setIsNewOrEditDialogOpen(true);
    };

    const handleClickDeleteServiceUserRow = () => {
      setCurrentRow(rowData);
      setIsDeleteDialogOpen(true);
    };

    return (
      <div className="actions">
        <Button
          icon="pi pi-envelope"
          className="p-button-rounded p-button-info p-mr-2 resend-invitation-button"
          onClick={handleClickResendInvitation}
          disabled={rowData.status === SERVICE_USER_STATUS.ACTIVE}
        />
        <Tooltip target={`.resend-invitation-button`} content={'Wyslij ponownie zaproszenie'} position="bottom" />

        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2 edit-user-button"
          onClick={handleClickEditServiceUserRow}
        />
        <Tooltip target={`.edit-user-button`} content={'Edytuj dane uzytkownika'} position="bottom" />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning delete-user-button"
          onClick={handleClickDeleteServiceUserRow}
        />
        <Tooltip target={`.delete-user-button`} content={'Usuń dane użytkownika'} position="bottom" />
      </div>
    );
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                ref={dt}
                value={users}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                rowHover
                removableSort
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                emptyMessage="Brak użytkowników"
              >
                <Column
                  field="email"
                  header="Adres e-mail"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.email}
                      onChangeFilter={handleChangeFilter}
                      name="email"
                      placeholder="Filtruj"
                    />
                  }
                />
                <Column
                  field="firstName"
                  header="Imie"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.firstName}
                      onChangeFilter={handleChangeFilter}
                      name="firstName"
                      placeholder="Filtruj"
                    />
                  }
                />
                <Column
                  field="lastName"
                  header="Nazwisko"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.lastName}
                      onChangeFilter={handleChangeFilter}
                      name="lastName"
                      placeholder="Filtruj"
                    />
                  }
                />
                <Column body={statusBodyTemplate} header="Status" className="status" />
                <Column body={createdAtBodyTemplate} field="createdAt" header="Data utworzenia" sortable />
                <Column body={actionBodyTemplate} style={{ width: '140px', textAlign: 'center' }} />
              </DataTable>
              {isNewOrEditDialogOpen && (
                <InviteOrEditServiceUserDialog
                  user={currentRow as ServiceUser}
                  customers={customers}
                  hideDialog={hideInviteOrEditDialog}
                  fetchComponentData={fetchComponentData}
                />
              )}
              {isDeleteDialogOpen && (
                <DeleteServiceUserDialog
                  setDeleteUserDialogVisible={setIsDeleteDialogOpen}
                  user={currentRow as ServiceUser}
                  deleteUser={deleteUser}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceUsers;
