import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import nodeModelService from '../../services/ScaleoAdminApiServices/NodeModelsService';

const DeleteNodeModelDialog = ({ visible, closeDialog, selectedRows }) => {
  const handleCloseDialog = (changes) => {
    closeDialog(changes === true);
  };

  const handleConfirm = async () => {
    await Promise.all(selectedRows.map((item) => nodeModelService.deleteNodeModel(item.id))).then(() => {
      handleCloseDialog(true);
    });
  };

  const dialogFooter = (
    <>
      <Button type="reset" label="Anuluj" icon="pi pi-times" className="p-button-text" onClick={handleCloseDialog} />
      <Button type="button" label="Usuń" icon="pi pi-check" className="p-button-text" onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
      header={<span style={{ display: 'flex', alignItems: 'center' }}>Potwierdź</span>}
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={handleCloseDialog}
    >
      {selectedRows ? (
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {Array.isArray(selectedRows) && selectedRows.length > 1 ? (
            <span>Czy na pewno chcesz usunąć wybrane pozycję?</span>
          ) : (
            <span>
              Czy na pewno chcesz usunąć <b>{selectedRows[0]?.name}</b> ?
            </span>
          )}
        </div>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default DeleteNodeModelDialog;
