import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { useEffect, useState } from 'react';
import './DataTable.css';
import { CustomButton, CustomTableField } from '../_shared/CustomComponents';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import RolesModal from './RolesModal';
import RolesDeleteModal from './RolesDeleteModal';
import useRoleData from '../../hooks/adminRoles/useRoleData';
import { AdminRole, AdminRolesFilters } from './Roles.types';
import useRoleForm from '../../hooks/adminRoles/useRoleForm';
import adminRolesService from '../../services/ScaleoAdminApiServices/AdminRolesService';

const EMPTY_ROLE = {
  id: null,
  name: '',
  permissions: [],
};

const initialFilters: AdminRolesFilters = {
  name: '',
};

export const Roles = () => {
  const [filters, setFilters] = useState<AdminRolesFilters>(initialFilters);

  const {
    data: roles,
    permissions,
    paginatorState,
    setPaginatorState,
    paginationParameters,
    setPaginationParameters,
    fetchData,
  } = useRoleData(filters);

  const { formik, resetForm } = useRoleForm(fetchData, paginationParameters);

  const [role, setRole] = useState(EMPTY_ROLE);
  const [availablePermissions, setAvailablePermissions] = useState(permissions);

  const [selectedRoles, setSelectedRoles] = useState<AdminRole[] | []>([]);

  const [isRolesModalOpen, setIsRolesModalOpen] = useState(false);
  const [isDeleteSingleRoleModalOpen, setIsDeleteSingleRoleModalOpen] = useState(false);
  const [isDeleteMultipleRolesModalOpen, setIsDeleteMultipleRolesModalOpen] = useState(false);

  useEffect(() => {
    void fetchData(paginationParameters);
  }, [filters]);

  const openNew = () => {
    setAvailablePermissions(permissions);
    setRole(EMPTY_ROLE);
    setIsRolesModalOpen(true);
  };

  const hideDialog = () => {
    setIsRolesModalOpen(false);
    resetForm();

    setAvailablePermissions([]);
  };
  const deleteSelectedRoles = () => {
    const selectedRolesIds = selectedRoles.map((selectedRole: AdminRole) => selectedRole.id);

    void adminRolesService.deleteMultipleRoles(selectedRolesIds).then(() => fetchData(paginationParameters));

    setIsDeleteMultipleRolesModalOpen(false);
    setSelectedRoles([]);
  };
  const confirmDeleteSelected = () => {
    setIsDeleteMultipleRolesModalOpen(true);
  };
  const hideDeleteRolesDialog = () => {
    setIsDeleteMultipleRolesModalOpen(false);
  };
  const confirmDeleteRole = (role: any) => {
    setRole(role);

    setIsDeleteSingleRoleModalOpen(true);
  };
  const hideDeleteRoleDialog = () => {
    setIsDeleteSingleRoleModalOpen(false);
  };

  const editRole = (role: any) => {
    resetForm({ id: role.id, name: role.name, permissions: role.permissions });

    setRole({ ...role });
    setIsRolesModalOpen(true);
    setAvailablePermissions(permissions.filter((ar: any) => !role.permissions.find((rm: any) => rm.id === ar.id)));
  };

  const deleteRole = () => {
    if (!role?.id) {
      return;
    }
    void adminRolesService.deleteAdminRole(role.id).then(() => fetchData(paginationParameters));

    setIsDeleteSingleRoleModalOpen(false);
    setRole(EMPTY_ROLE);
  };

  const changeFilter = (value: any, name: any) => {
    const newFilters: any = { ...filters };
    newFilters[name] = value;
    setFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const header = (
    <div className="table-header">
      {'Lista ról i uprawnień'} &#160;&#160;
      <div className="p-text-right">
        <Button
          label={'Wyczyść filtry'}
          icon="pi pi-filter-slash"
          className="p-button-outlined p-mr-2"
          onClick={clearFilters}
        />
      </div>
    </div>
  );

  const leftToolbarTemplate = () => {
    return (
      <>
        <Button label="Dodaj" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={openNew} />
        <CustomButton
          label="Usuń"
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={confirmDeleteSelected}
          disabled={
            !selectedRoles || !selectedRoles.length || selectedRoles?.map((role: any) => role?.isUsed)?.includes(true)
          }
          tooltip={!selectedRoles.length ? 'Wybierz rolę' : 'Nie można usunąć roli'}
          type="submit"
          name="test"
          dataFlag={'sometest'}
        />
      </>
    );
  };
  const rightToolbarTemplate = () => <></>;

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editRole(rowData)}
        />
        {rowData?.isUsed === false ? (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-warning"
            onClick={() => confirmDeleteRole(rowData)}
          />
        ) : (
          <Button
            icon="pi pi-trash"
            className="p-button-rounded p-button-secondary"
            tooltip={rowData?.name}
            tooltipOptions={{ position: 'bottom' }}
          />
        )}
      </div>
    );
  };

  const columnBodyTemplate = (rowData: any, column: any) => {
    return <CustomTableField row={rowData} col={column} withoutHeaders={undefined} />;
  };

  type ColumnType = {
    field: string;
    header: string;
    type: string;
    width?: string;
  };

  const columns: ColumnType[] = [
    { field: 'name', header: 'Nazwa', type: 'text' },
    { field: 'createdAt', header: 'Data utworzenia', type: 'datetime' },
    { field: 'permissions', header: 'Uprawnienia', type: 'multiple', width: '50%' },
  ];

  const dynamicColumns = columns.map((col: ColumnType) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        body={columnBodyTemplate}
        fieldsType={col.type}
        sortable={col.type !== 'multiple' ? true : false}
        filter={col.type === 'text' ? true : false}
        style={{ width: col?.width }}
        filterElement={
          col.type === 'text' ? (
            <DataTableFilterElements.Text
              initialValue={filters.name}
              onChangeFilter={changeFilter}
              name={col.field}
              placeholder={'Filtruj'}
            />
          ) : null
        }
      ></Column>
    );
  });

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                value={roles}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                rowHover
                removableSort
                selectionMode="checkbox"
                selection={selectedRoles}
                onSelectionChange={(e) => setSelectedRoles(e.value)}
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                emptyMessage={'Brak rekordów'}
              >
                <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                {dynamicColumns}
                <Column body={actionBodyTemplate} className="actionBody"></Column>
              </DataTable>
              {isRolesModalOpen && (
                <RolesModal
                  availablePermissions={availablePermissions}
                  setAvailablePermissions={setAvailablePermissions}
                  formik={formik}
                  closeModalHandler={hideDialog}
                />
              )}

              {isDeleteMultipleRolesModalOpen && (
                <RolesDeleteModal
                  selectedRoles={selectedRoles}
                  deleteRoleHandler={deleteSelectedRoles}
                  closeModalHandler={hideDeleteRolesDialog}
                  isSingleDelete={false}
                />
              )}
              {isDeleteSingleRoleModalOpen && (
                <RolesDeleteModal
                  role={role}
                  deleteRoleHandler={deleteRole}
                  closeModalHandler={hideDeleteRoleDialog}
                  isSingleDelete={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Roles;
