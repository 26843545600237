import axios from 'axios';
import { License } from '../../types/license';

class LicensesService {
  async searchLicenses(filters?: any, paginationParameters?: any, globalFilter?: any) {
    return axios.post('/licenses/search', { filters, paginationParameters, globalFilter });
  }
  async postLicense(data: License) {
    return axios.post(`/licenses`, data);
  }
  async editLicense(data: License, id: string) {
    return axios.put(`/licenses/${id}`, data);
  }
  async removeLicense(id: string) {
    return axios.delete(`/licenses/${id}`);
  }
  async generateLicenseKey(licenseId: string, mail: string, deviceNumberHash: string) {
    return axios.post('/licenses/generate/license-key', { licenseId, mail, deviceNumberHash });
  }
}
const licensesService = new LicensesService();
export default licensesService;
