export function adaptJsonToStrings(obj: any): any {
  if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
    for (const key in obj) {
      if (obj[key]) {
        obj[key] = adaptJsonToStrings(obj[key]);
      }
    }
  } else if (Array.isArray(obj)) {
    obj = obj.map((item) => adaptJsonToStrings(item));
  } else if (obj !== null && obj !== undefined && typeof obj.toString === 'function') {
    obj = obj.toString();
  }

  return obj;
}
