export enum Permission {
  ADMIN_USERS_MANAGEMENT = 'ADMIN_USERS_MANAGEMENT',
  ROLES_MANAGEMENT = 'ROLES_MANAGEMENT',
  ACCESS_TO_EVENT_LOG = 'ACCESS_TO_EVENT_LOG',
  CUSTOMER_MANAGEMENT = 'CUSTOMER_MANAGEMENT',
  SERVICE_USERS_MANAGEMENT = 'SERVICE_USERS_MANAGEMENT',
  SCALEO_USERS_MANAGEMENT = 'SCALEO_USERS_MANAGEMENT',
  NODE_MODELS_MANAGEMENT = 'NODE_MODELS_MANAGEMENT',
  DEPLOYMENTS_MANAGEMENT = 'DEPLOYMENTS_MANAGEMENT',
  LOCATIONS_MANAGEMENT = 'LOCATIONS_MANAGEMENT',
  STATIONS_MANAGEMENT = 'STATIONS_MANAGEMENT',
  RECOGNITIONS = 'RECOGNITIONS',
}
