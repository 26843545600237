import { Dialog } from 'primereact/dialog';
import { Result } from '../../../types/recognition';
import { Tree } from 'primereact/tree';
import { jsonToTreeData } from '../../../utils/json-to-tree-data';
import { useRef } from 'react';

interface ResultsViewerDialogProps {
  resultsObject: Result[] | null;
  setResultsObject: (r: Result[] | null) => void;
}

export const ResultsViewerDialog = ({ resultsObject, setResultsObject }: ResultsViewerDialogProps) => {
  const ref = useRef(null);

  return resultsObject ? (
    <Dialog
      ref={ref}
      visible
      className="p-fluid"
      modal
      onHide={() => setResultsObject(null)}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
    >
      {' '}
      <div className="field col-12">
        <Tree
          style={{ marginBottom: '2vh' }}
          value={jsonToTreeData(resultsObject)}
          expandedKeys={BASE_EXPANDED_KEYS}
          nodeTemplate={(node: any, options: any) => {
            let label = node.label;

            if (!node.children) {
              label = (
                <>
                  {node.label}:&nbsp;
                  <b>{node.data}</b>
                </>
              ) as any;
            }

            return <span className={options.className}>{label}</span>;
          }}
        />
      </div>
    </Dialog>
  ) : (
    <></>
  );
};

const BASE_EXPANDED_KEYS = { '0': true, box: true, region: true, vehicle: true, candidates: true };
