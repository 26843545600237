import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import React, { useContext, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosConfigContainer } from '../components/AxiosConfig';
import AuthContext from '../store/auth-context';
import { uiActions } from '../store/ui-slice';

export const Login = (props) => {
  const authCtx = useContext(AuthContext);
  const dispatch = useDispatch();

  const [usernameOrPasswordInvalid, setUsernameOrPasswordInvalid] = useState(false);
  const [usernameEmpty, setUsernameEmpty] = useState(false);
  const [passwordEmpty, setPasswordEmpty] = useState(false);
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  const scheduleHideLoader = () => {
    setTimeout(() => {
      dispatch(uiActions.toggleIsLoading({ isLoading: false }));
    }, 400);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const enteredUsername = usernameInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    const valid = validateUsernameAndPassword(enteredUsername, setUsernameEmpty, enteredPassword, setPasswordEmpty);

    dispatch(uiActions.toggleIsLoading({ isLoading: true }));
    if (valid) {
      props.onLoginAttempt(enteredUsername, enteredPassword, (error, token, id, username) => {
        if (error && (error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException')) {
          setUsernameOrPasswordInvalid(true);
          scheduleHideLoader();
          return;
        }
        authCtx.login(token, id, username, null);
        scheduleHideLoader();
      });
    } else {
      scheduleHideLoader();
    }
  };

  return (
    <>
      <AxiosConfigContainer />
      <form onSubmit={submitHandler}>
        <div className="login-body">
          <div className="card login-panel p-fluid">
            <div className="login-panel-content">
              <div className="p-grid">
                <div className="p-col-12 p-sm-6 p-md-6 logo-container">
                  <span className="guest-sign-in">Logowanie do panelu administracyjnego systemu Scaleo</span>
                </div>
                <div className="p-col-12">
                  <label>Nazwa użytkownika</label>
                  <div className="login-input p-mb-2">
                    <InputText id="input" type="text" ref={usernameInputRef} className={usernameEmpty && 'p-invalid'} />
                  </div>
                  {usernameEmpty && <Message severity="error" text="Nazwa użytkownika jest wymagana" />}
                </div>
                <div className="p-col-12 password-container">
                  <label>Hasło</label>
                  <div className="login-input p-mb-2">
                    <InputText type="password" ref={passwordInputRef} className={passwordEmpty && 'p-invalid'} />
                  </div>
                  {passwordEmpty && <Message severity="error" text="Hasło jest wymagane" />}
                </div>
                {usernameOrPasswordInvalid === true && (
                  <div className="p-col-12 invalid-login-or-password-container">
                    <Message severity="error" text="Nieprawidłowy login i/lub hasło." />
                  </div>
                )}

                <div className="p-col-12 p-sm-6 p-md-6">
                  <Button label="Zaloguj" icon="pi pi-check" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

function validateUsernameAndPassword(enteredUsername, setUsernameEmpty, enteredPassword, setPasswordEmpty) {
  if (enteredUsername === '') {
    setUsernameEmpty(true);
  } else {
    setUsernameEmpty(false);
  }
  if (enteredPassword === '') {
    setPasswordEmpty(true);
  } else {
    setPasswordEmpty(false);
  }
  return !(enteredUsername === '' || enteredPassword === '');
}
