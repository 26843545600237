export const jsonToTreeData = (jsonData: any) => {
  const getKey = (key: string, parentKey?: string) => {
    if (parentKey) {
      return `${parentKey}-${key}`;
    }
    return key;
  };

  const prepareTreeNode = (node: any, parentKey?: string) => {
    const result = [] as any;
    Object.entries(node).forEach(([key, value]) => {
      if (typeof value === 'string') {
        result.push({ key, label: key, data: value });
      } else if (Array.isArray(value)) {
        result.push({ key, label: key, children: prepareTreeNode(value, key) });
      } else {
        result.push({ key: getKey(key, parentKey), label: key, children: prepareTreeNode(value) });
      }
    });
    return result;
  };

  return prepareTreeNode(jsonData);
};
