import React, { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../DataTable.css';

const DeleteUserDialog = ({ deleteUserDialogVisible, setDeleteUserDialogVisible, user, deleteUser }) => {
  const [correctEmailValidation, setCorrectEmailValidation] = useState(true);

  const emailValidation = useRef(null);

  const hideDeleteUserDialog = () => {
    setDeleteUserDialogVisible(false);
    setCorrectEmailValidation(true);
  };

  const validationDeleteUser = () => {
    if (emailValidation.current.value === user.email) {
      deleteUser();
      setCorrectEmailValidation(true);
    } else setCorrectEmailValidation(false);
  };

  const deleteUserDialogFooter = (
    <>
      <Button label="Nie" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
      <Button label="Tak" icon="pi pi-check" className="p-button-text" onClick={validationDeleteUser} />
    </>
  );

  return (
    <Dialog
      visible={deleteUserDialogVisible}
      className="p-fluid"
      header={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          Potwierdź
        </span>
      }
      modal
      footer={deleteUserDialogFooter}
      onHide={hideDeleteUserDialog}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
    >
      <div className="confirmation-content">
        {user && (
          <>
            <span>
              Czy na pewno chcesz usunąć{' '}
              <b>
                {user.firstName} {user.lastName}
              </b>{' '}
              ?
            </span>
            <div className="field p-my-5">
              <label htmlFor="email-validation" className="block">
                E-mail
              </label>
              <InputText
                id="email-validation"
                aria-describedby="email-validation"
                className={`block p-my-2 ${!correctEmailValidation && 'p-invalid'}`}
                ref={emailValidation}
              />
              <small id="email-validation" className="block">
                W celu weryfikacji, podaj adres e-mail użytkownika, którego chcesz usunąć.
              </small>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default DeleteUserDialog;
