import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation, withRouter } from 'react-router-dom';
import { ReducerState } from '../../scaleo-ui/src/types/reducer-state';
import App from './App';
import { Error } from './pages/Error';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { scaleoModeContext } from './services/ScaleoModeContext';

interface AppWrapperProps {
  location: {
    pathname: string;
  };
}

const AppWrapper = ({ location: { pathname } }: AppWrapperProps) => {
  const location = useLocation();
  const history = useHistory();
  const loggedUser = useSelector((state: ReducerState) => state.user.loggedUser);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    loggedUser && history.replace('/');
    !loggedUser && history.replace('/login');
  }, [history, loggedUser]);

  switch (pathname) {
    case '/':
      return (
        <Route path="/">
          {loggedUser && <App />}
          {!loggedUser && <Redirect to="/login" />}
        </Route>
      );
    case '/login':
      return (
        <Route
          path="/login"
          render={(props) => <Login {...props} onLoginAttempt={scaleoModeContext().authenticateUserHandler} />}
        />
      );
    case '/error':
      return <Route path="/error" component={Error} />;
    case '/notfound':
      return <Route path="/notfound" component={NotFound} />;
    default:
      return <App />;
  }
};

export default withRouter(AppWrapper);
