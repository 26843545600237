import { Dialog } from 'primereact/dialog';
import { Galleria } from 'primereact/galleria';
import { Recognition } from '../../../types/recognition';
import { Dispatch, SetStateAction, useRef } from 'react';
import Magnifier from 'react-magnifier';

const MagnifierAny: any = Magnifier;

interface RecognitionsViewerProps {
  recognitions: Recognition[];
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
}

export default function RecognitionsViewer({ recognitions, activeIndex, setActiveIndex }: RecognitionsViewerProps) {
  const ref = useRef<any>(null);

  const itemTemplate = (item: Recognition) => (
    <div className="gallery-image-magnifier">
      <MagnifierAny src={item.url} height="100%" width="100%" mgWidth={200} mgHeight={200} zoomFactor={2} />
    </div>
  );

  return Number.isInteger(activeIndex) ? (
    <Dialog
      ref={ref}
      visible={Number.isInteger(activeIndex)}
      className="p-fluid"
      modal
      onHide={() => setActiveIndex(null)}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
    >
      <Galleria
        activeIndex={activeIndex as number}
        circular
        showItemNavigators
        value={recognitions}
        showThumbnails={false}
        onItemChange={(e) => setActiveIndex(e.index)}
        item={itemTemplate}
      />
    </Dialog>
  ) : (
    <></>
  );
}
