import classNames from 'classnames';
import { Avatar } from 'primereact/avatar';
import React, { SyntheticEvent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AuthContext from './store/auth-context';
import { ReducerState } from '../../scaleo-ui/src/types/reducer-state';

interface AppTopbarProps {
  onTopbarItemClick: any;
  onMenuButtonClick: any;
  inlineUser: boolean;
  activeTopbarItem: string | null;
}

const AppTopbar = ({
  onMenuButtonClick,
  inlineUser,
  activeTopbarItem,
  onTopbarItemClick: propsOnTopbarItemClick,
}: AppTopbarProps) => {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const loggedUser = useSelector((state: ReducerState) => state.user.loggedUser);
  const loggedUserDetails = useSelector((state: ReducerState) => state.user.details);

  const onTopbarItemClick = (event: SyntheticEvent, item: any) => {
    if (propsOnTopbarItemClick) {
      propsOnTopbarItemClick({
        originalEvent: event,
        item: item,
      });
    }
  };

  const onLogoutClicked = () => {
    authCtx.logout();
  };

  return (
    <div className="layout-topbar">
      <button type="button" className="p-link layout-menu-button layout-topbar-icon" onClick={onMenuButtonClick}>
        <i className="pi pi-bars"></i>
      </button>
      <button type="button" className="p-link layout-topbar-logo" onClick={() => history.push('/')}>
        <img id="topbar-logo" src="assets/layout/images/miw-logo.png" alt="Miary i Wagi" />
      </button>
      <span className="layout-topbar-app-context">
        <span className="layout-topbar-company">
          <span className="layout-topbar-label">Dystrybutor:</span>
          <span className="layout-topbar-name">MIW Group</span>
        </span>
      </span>

      <ul className="topbar-menu" style={{ display: ' inline-flex', alignItems: 'center', height: '45px' }}>
        <li className={classNames('user-profile', { 'active-topmenuitem fadeInDown': activeTopbarItem === 'profile' })}>
          {!inlineUser && (
            <button type="button" className="p-link" onClick={(e) => onTopbarItemClick(e, 'profile')}>
              <Avatar label={loggedUser?.username?.charAt(0)} className="" size="large" shape="circle"></Avatar>
              <div className="layout-profile-userinfo">
                <span className="layout-profile-name">
                  {loggedUserDetails.firstName} {loggedUserDetails.lastName}
                </span>
                <span className="layout-profile-role">{loggedUserDetails.role}</span>
              </div>
            </button>
          )}

          <ul className="fadeInDown">
            <li role="menuitem">
              <button type="button" className="p-link" onClick={() => history.push('/user-profile')}>
                <i className="pi pi-fw pi-user"></i>
                <span>Profil</span>
              </button>
              <button type="button" className="p-link" onClick={() => onLogoutClicked()}>
                <i className="pi pi-fw pi-user"></i>
                <span>Wyloguj</span>
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default AppTopbar;
