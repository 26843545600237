import { useFormik } from 'formik';
import { PaginationParams } from '../../types/general-types';
import locationsService from '../../services/ScaleoAdminApiServices/LocationsService';
import validator from 'validator';

const emptyLocation = {
  id: '',
  name: '',
  shortName: '',
  city: '',
  customer: {
    name: '',
    value: '',
  },
  postalCode: '',
  street: '',
  isTestWeighing: false,
};

const useLocationForm = (
  fetchData: (paginationParameters: PaginationParams) => void,
  paginationParameters: PaginationParams,
) => {
  const formik = useFormik({
    initialValues: emptyLocation,
    validate: (data) => {
      const errors: any = {};

      if (!data.name) {
        errors.name = 'Nazwa oddziału jest wymagana';
      } else {
        if (!data.name.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ 0-9-&]+$/)) {
          errors.name = 'Nazwa oddziału musi zawierać tylko litery, cyfry, spacje, myślniki i znaki &';
        }
      }

      if (!data.shortName) {
        errors.shortName = 'Skrócona nazwa oddziału jest wymagana';
      } else {
        if (!data.shortName.match(/^[A-ZŻŹĆĄŚĘŁÓŃ 0-9-&]+$/)) {
          errors.shortName =
            'Skrócona nazwa oddziału musi zawierać tylko wielkie litery, cyfry, spacje, myślniki i znaki &';
        }
      }

      if (!data.street) {
        errors.street = 'Ulica jest wymagana';
      } else {
        if (!data.street.match(/^[\p{L}0-9-./\s]+$/u)) {
          errors.street = 'Ulica musi zawierać tylko litery, cyfry, spacje, myślniki, kropki i ukośniki';
        }
      }

      if (!data.postalCode) {
        errors.postalCode = 'Kod pocztowy jest wymagany';
      } else {
        if (data.postalCode && !validator.isPostalCode(data.postalCode, 'any')) {
          errors.postalCode = 'Kod pocztowy jest niepoprawny';
        }
      }

      if (!data.city) {
        errors.city = 'Miejscowość jest wymagana';
      } else {
        if (!data.city.match(/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ -]+$/)) {
          errors.city = 'Miejscowość musi zawierać tylko litery, spacje, myślniki i znaki &';
        }
      }

      if (!data.customer?.value) {
        errors.customer = 'Klient jest wymagany';
      }

      return errors;
    },
    onSubmit: async (formData: any, helpers) => {
      const { customer, ...newFormData } = formData;

      newFormData.customerId = customer.value;

      if (!formData.id) {
        locationsService
          .addLocation(newFormData)
          .then(() => {
            fetchData(paginationParameters);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('email', 'Taki oddział juz istnieje');
            }
          })
          .finally(() => helpers.setSubmitting(false));
      } else {
        locationsService
          .editLocation(newFormData, newFormData.id)
          .then(() => {
            fetchData(paginationParameters);
          })
          .catch((error) => {
            if (error.response.status === 409) {
              helpers.setFieldError('name', 'Taki oddział juz istnieje');
            }
          })
          .finally(() => helpers.setSubmitting(false));
      }
    },
    enableReinitialize: true,
  });

  const updateForm = (data: any = emptyLocation) => {
    formik.resetForm({ values: data });
  };

  return { formik, updateForm };
};

export default useLocationForm;
