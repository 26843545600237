export enum StationType {
  WEIGHING_STATION = 'WEIGHING_STATION',
  GATE = 'GATE',
}

export interface Station {
  id: string | null;
  name: string;
  installationId: string | null;
  anprApiKey?: string | null;
  locationId: string;
  edgeDeviceId: string;
  customerId: string;
  type: StationType;
  vpnAddress: string;
  remarks: string;
}
