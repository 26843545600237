import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

class NodeModelsService {
  async searchNodeModels(paginationParameters) {
    const nodeModels = await axios.post('/node-models/search', { paginationParameters });
    return {
      data: {
        items: nodeModels.data.items.map((nodeModel) => {
          return this.addCommandIndexing(nodeModel);
        }),
        totalPagesNumber: nodeModels.data.totalPagesNumber,
      },
    };
  }
  async editNodeModel(data, id) {
    return await axios.put(`/node-models/${id}`, this.removeCommandIndexing(data));
  }
  async deleteNodeModel(id) {
    return await axios.delete(`/node-models/${id}`);
  }
  async createNodeModel(data, requiredCommands) {
    return await axios.post(`/node-models/`, { data: this.removeCommandIndexing(data), requiredCommands });
  }
  addCommandIndexing(nodeModel) {
    return {
      ...nodeModel,
      protocol: {
        commands: nodeModel.protocol.commands?.map((command) => {
          return { ...command, id: uuidv4() };
        }),
      },
    };
  }
  removeCommandIndexing(nodeModel) {
    return {
      ...nodeModel,
      protocol: {
        commands: nodeModel.protocol.commands?.map((command) => {
          delete command.id;
          return command;
        }),
      },
    };
  }
}

const nodeModelService = new NodeModelsService();
export default nodeModelService;
