import React, { useEffect, useState } from 'react';
import { FormikHelpers, useFormik } from 'formik';
import validator from 'validator';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import customerUsersService from '../../services/ScaleoAdminApiServices/CustomerUsersService';
import { Customer } from '../../types/customer';
import { ScaleoUser, ScaleoUserType } from '../../types/scaleo-user';
import FormErrorMessage from '../_shared/FormErrorMessage';
import FormErrorMessageScroller from '../_shared/FormErrorMessageScroller';
import { CustomAutoComplete } from '../_shared/CustomComponents';
import '../DataTable.css';

type Props = {
  visible: boolean;
  setVisible: (arg: boolean) => void;
  user: any;
  customers: any[] | undefined;
};

type FormState = Partial<ScaleoUser> & { customer: Customer | null };

const INIT_FORM_STATE = {
  customer: null,
  email: '',
  firstName: '',
  id: '',
  lastName: '',
} as FormState;

const InviteOrEditScaleoUserDialog = ({ visible, setVisible, user, customers }: Props) => {
  const [initFormValues, setInitFormValues] = useState(INIT_FORM_STATE);

  useEffect(() => {
    setInitFormValues({ ...INIT_FORM_STATE, ...user });
  }, [user, visible]);

  const hideDialog = () => {
    formik.resetForm({ values: INIT_FORM_STATE });
    setVisible(false);
  };

  const validateMethod = (data: FormState) => {
    const errors = {} as any;

    if (!data.customer) {
      errors.customer = 'Wybierz klienta';
    }
    if (!data.firstName) {
      errors.firstName = 'Podaj imię';
    }
    if (!data.lastName) {
      errors.lastName = 'Podaj nazwisko';
    }
    if (!data.email) {
      errors.email = 'Podaj adres e-mail';
    } else if (!validator.isEmail(data.email)) {
      errors.email = 'Niepoprawny e-mail';
    }
    return errors;
  };

  const handleSubmit = (data: FormState, helpers: FormikHelpers<FormState>) => {
    if (data?.id) {
      customerUsersService
        .editCustomerUser(
          { ...data, customerId: data.customer?.id, type: data.type ?? ScaleoUserType.INTERNAL_USER },
          data.id,
        )
        .then(() => {
          hideDialog();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            helpers.setFieldError('email', 'Istnieje już użytkownik z podanym adresem e-mail.');
          }
        })
        .finally(() => helpers.setSubmitting(false));
    } else {
      customerUsersService
        .inviteCustomerUser(data)
        .then(() => {
          hideDialog();
        })
        .catch((error) => {
          if (error.response.status === 409) {
            if (error.response.data.error === 'USERS_LIMIT_REACHED') {
              helpers.setFieldError('email', 'Osiągnięto limit użytkowników');
            } else {
              helpers.setFieldError('email', 'Istnieje już użytkownik z podanym adresem e-mail.');
            }
          }
        })
        .finally(() => helpers.setSubmitting(false));
    }
  };

  const formik = useFormik({
    initialValues: initFormValues,
    validate: validateMethod,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const userDialogFooter = (
    <>
      <Button type="reset" label="Anuluj" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
      <Button
        type="submit"
        label={formik.values.id ? 'Zapisz' : 'Wyślij zaproszenie'}
        icon="pi pi-check"
        className="p-button-text"
        onClick={formik.submitForm}
      />
    </>
  );

  return (
    <FormErrorMessageScroller formikInstance={formik}>
      <Dialog
        visible={visible}
        header="Szczegóły użytkownika"
        modal
        className="p-fluid"
        footer={userDialogFooter}
        onHide={hideDialog}
        breakpoints={{ '1400px': '60vw', '896px': '90vw' }}
        style={{ width: '40vw' }}
      >
        <form>
          <div className="p-col-10">
            <div className="p-field">
              <label htmlFor="role">Klient</label>
              <CustomAutoComplete
                id="customer"
                value={formik.values.customer}
                handler={formik.handleChange}
                source={customers}
                lookupField="name"
                inputStyle={undefined}
                customSearchBranch={undefined}
                customItemTemplate={undefined}
                placeholder={undefined}
                scrollHeight={undefined}
                tooltip={undefined}
                disabled={undefined}
                forceSelection={undefined}
                onBlur={undefined}
              />
              <FormErrorMessage fieldName="customer" formikInstance={formik} />
            </div>
            <div className="p-field">
              <label htmlFor="email">E-mail</label>
              <InputText id="email" value={formik.values.email} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="email" formikInstance={formik} />
            </div>
            <div className="p-field">
              <label htmlFor="firstName">Imię</label>
              <InputText id="firstName" value={formik.values.firstName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="firstName" formikInstance={formik} />
            </div>
            <div className="p-field">
              <label htmlFor="lastName">Nazwisko</label>
              <InputText id="lastName" value={formik.values.lastName} onChange={formik.handleChange} />
              <FormErrorMessage fieldName="lastName" formikInstance={formik} />
            </div>
          </div>
        </form>
      </Dialog>
    </FormErrorMessageScroller>
  );
};

export default InviteOrEditScaleoUserDialog;
