import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userActions } from './user-slice';

const AuthContext = React.createContext({
  login: () => {
    // initial login method
  },
  logout: () => {
    // initial logout method
  },
});

const retrieveStoredLoggedUser = (dispatch) => {
  const storedToken = localStorage.getItem('token');
  const storedId = localStorage.getItem('id');
  const storedUsername = localStorage.getItem('username');

  storedToken &&
    storedId &&
    storedUsername &&
    dispatch(
      userActions.setLoggedUser({
        token: storedToken,
        id: storedId,
        username: storedUsername,
      }),
    );
};

export const AuthContextProvider = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  retrieveStoredLoggedUser(dispatch);

  const logoutHandler = useCallback(() => {
    dispatch(userActions.setLoggedUser(null));

    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('username');
    localStorage.removeItem('expirationTime');

    history.replace('/login');
  }, [dispatch, history]);

  const loginHandler = (idToken, id, username, expirationTime) => {
    dispatch(userActions.setLoggedUser({ token: idToken, id, username }));
    localStorage.setItem('token', idToken);
    localStorage.setItem('id', id);
    localStorage.setItem('username', username);
    localStorage.setItem('expirationTime', expirationTime);
  };

  const contextValue = {
    login: loginHandler,
    logout: logoutHandler,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
