import { useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import '../DataTable.css';
import { AdminUserDeleteModalProps } from './AdminUsers.types';

const AdminUserDeleteModal = ({ closeModalHandler, adminUser, deleteAdminUser }: AdminUserDeleteModalProps) => {
  const [correctEmailValidation, setCorrectEmailValidation] = useState(true);

  const ref = useRef<any>(null);

  const hideDeleteUserDialog = () => {
    setCorrectEmailValidation(true);
    closeModalHandler();
  };

  const validationDeleteAdminUser = () => {
    if (ref.current.value === adminUser?.email) {
      deleteAdminUser();
      setCorrectEmailValidation(true);
    } else setCorrectEmailValidation(false);
  };

  const deleteUserDialogFooter = (
    <>
      <Button label="Nie" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
      <Button label="Tak" icon="pi pi-check" className="p-button-text" onClick={validationDeleteAdminUser} />
    </>
  );

  return (
    <div>
      <Dialog
        visible={true}
        className="p-fluid"
        header={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
            Potwierdź
          </span>
        }
        modal
        footer={deleteUserDialogFooter}
        onHide={hideDeleteUserDialog}
        breakpoints={{ '896px': '90vw' }}
        style={{ minWidth: '450px' }}
      >
        <div className="confirmation-content">
          {adminUser && (
            <>
              <span>
                Czy na pewno chcesz usunąć{' '}
                <b>
                  {adminUser.firstName} {adminUser.lastName}
                </b>{' '}
                ?
              </span>
              <div className="field p-my-5">
                <label htmlFor="email-validation" className="block">
                  E-mail
                </label>
                <InputText
                  id="email-validation"
                  aria-describedby="email-validation"
                  className={`block p-my-2 ${!correctEmailValidation && 'p-invalid'}`}
                  ref={ref}
                />
                <small id="email-validation" className="block">
                  W celu weryfikacji, podaj adres e-mail administratora, którego chcesz usunąć.
                </small>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default AdminUserDeleteModal;
