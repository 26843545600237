import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';
import { useEffect, useState } from 'react';
import formatDate from './formatDate';
import formatDateTime from './formatDateTime';
import formatDecimal from './formatDecimal';

export const CustomTableField = ({ col, row, withoutHeaders }) => {
  const { fieldsType, header, field, emptyValue } = col;

  if (fieldsType) {
    switch (fieldsType) {
      case 'text':
      case 'noFilter':
      case 'dropdown':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] ? row[field] : emptyValue || null}
          </>
        );
      case 'scalesNames':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {row[col.field]?.join(', ')}
          </>
        );
      case 'weighingNumber':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] ? <>{row[field]}</> : emptyValue || null}
          </>
        );
      case 'number':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {row[col.field]
              ? formatDecimal(row[col.field])
              : row[col.field] === 0
              ? row[col.field]
              : emptyValue || null}
          </>
        );
      case 'date':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] ? formatDate(row[field]) : emptyValue || null}
          </>
        );
      case 'datetime':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] ? formatDateTime(row[field]) : emptyValue || null}
          </>
        );
      case 'multiple':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? header : null}</span> : ''}
            {row[field] instanceof Array
              ? row[field].map((item) => {
                  return item.name ? <Chip key={item.id} label={item.name} className="p-mr-2 p-mb-2" /> : null;
                })
              : null}
          </>
        );
      case 'isConfirmed':
        return (
          <>
            {!withoutHeaders ? <span className="p-column-title">{col ? col.header : null}</span> : ''}
            {<i className={row.isConfirmed ? 'pi pi-check' : 'pi pi-times'} />}
          </>
        );
      default:
        return null;
    }
  }
  return null;
};

export const CustomButton = ({ label, icon, name, className, dataFlag, tooltip, disabled, onClick, type }) => {
  return (
    <>
      <Tooltip target={`.${name}-tooltip`} content={disabled ? tooltip : ''} position="bottom" />
      <div className={`${name}-tooltip`}>
        <Button
          type={type || 'submit'}
          className={className}
          label={label}
          icon={icon}
          name={name}
          data-flag={dataFlag}
          disabled={disabled}
          onClick={onClick}
        />
      </div>
    </>
  );
};

export const CustomAutoComplete = ({
  lookupField,
  source,
  id,
  value,
  handler,
  inputStyle,
  customSearchBranch,
  customItemTemplate,
  placeholder,
  scrollHeight,
  tooltip,
  disabled,
  forceSelection,
  onBlur,
  ...rest
}) => {
  const [items, setItems] = useState(null);
  const [filteredItems, setFilteredItems] = useState(null);
  const [searchText, setSearchText] = useState('');
  const field = lookupField !== undefined ? lookupField : 'name';
  const placeholderField = placeholder !== undefined ? placeholder : '';
  const forceSelectionField = forceSelection !== undefined ? forceSelection : true;

  useEffect(() => {
    setItems(source);
  }, [source]);

  useEffect(() => {
    if (!value) {
      setSearchText('');
    } else if (value !== searchText && value[field]) {
      if (value[field].replace(/[^a-z0-9]/gi, '') === value[field]) {
        setSearchText('');
      }
    }
  }, [searchText, value, field]);

  const searchBranch = (event) => {
    setTimeout(() => {
      if (customSearchBranch) {
        customSearchBranch(event, items, setFilteredItems, field);
      } else {
        if (!event.query.trim().length) {
          setFilteredItems([...items]);
        } else {
          setFilteredItems(
            items.filter((item) => {
              return item[field].toLowerCase().includes(event.query.toLowerCase());
            }),
          );
        }
      }
    }, 250);
  };

  const onSelect = () => {
    setSearchText('');
  };

  const itemTemplate = (item) => {
    if (typeof customItemTemplate === 'function') {
      return customItemTemplate(item);
    } else {
      return (
        <div className="item">
          <div>{item[field]}</div>
        </div>
      );
    }
  };

  const valueTemplate = (_value) => {
    return searchText || _value;
  };

  const onChange = (event) => {
    if (typeof event.value === 'string') {
      const newValue = event.value;

      event.originalEvent.target.value = newValue;
      setSearchText(newValue);
    }

    if (typeof handler === 'function') {
      handler(event);
    }
  };

  return (
    <AutoComplete
      id={id}
      value={valueTemplate(value)}
      suggestions={filteredItems}
      completeMethod={searchBranch}
      field={field}
      dropdown
      forceSelection={forceSelectionField}
      onBlur={onBlur}
      itemTemplate={itemTemplate}
      onChange={onChange}
      onSelect={onSelect}
      inputStyle={inputStyle}
      placeholder={placeholderField}
      scrollHeight={scrollHeight}
      tooltip={tooltip}
      disabled={disabled}
      {...rest}
    />
  );
};
