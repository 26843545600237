import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable, DataTableSortParams } from 'primereact/datatable';
import { Toolbar } from 'primereact/toolbar';
import { useEffect, useRef, useState } from 'react';
import '../DataTable.css';
import DataTableFilterElements from '../_shared/DataTableFilterElements';
import formatDateTime from '../_shared/formatDateTime';
import getPaginatorTemplate from '../_shared/getPaginatorTemplate';
import { AdminUser, Filters } from './AdminUsers.types';
import AdminUserModal from './AdminUserModal';
import AdminUserDeleteModal from './AdminUserDeleteModal';
import useAdminData from '../../hooks/adminUsers/useAdminData';
import adminUsersService from '../../services/ScaleoAdminApiServices/AdminUsersService';
import useAdminUsersForm from '../../hooks/adminUsers/useAdminUsersForm';
import { AdminRole } from '../roles/Roles.types';
import { Tooltip } from 'primereact/tooltip';

const emptyFilters: Filters = {
  global: null,
  firstName: null,
  lastName: null,
  email: null,
};

const AdminUsers = () => {
  const dt = useRef(null);

  const [filters, setFilters] = useState<Filters>(emptyFilters);
  const [adminRoles, setAdminRoles] = useState<AdminRole[]>([]);

  const {
    data: adminUsers,
    paginatorState,
    setPaginatorState,
    paginationParameters,
    setPaginationParameters,
    fetchData,
  } = useAdminData(filters, setAdminRoles);

  const { formik, updateForm } = useAdminUsersForm(fetchData, paginationParameters);

  const [isAdminUserDeleteModalOpen, setIsAdminUserDeleteModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentRow, setCurrentRow] = useState<AdminUser | null>(null);

  useEffect(() => {
    void fetchData(paginationParameters);
  }, [JSON.stringify(filters)]);

  const closeModalHandler = () => {
    setIsModalOpen(false);
  };

  const deleteAdminUser = () => {
    if (!currentRow) {
      return;
    }
    void adminUsersService.deleteAdminUser(currentRow.id).then(() => {
      setIsAdminUserDeleteModalOpen(false);
      void fetchData(paginationParameters);
    });
  };

  const closeAdminUserDeleteModalHandler = () => {
    setIsAdminUserDeleteModalOpen(false);
  };

  const createdAtBodyTemplate = (rowData: AdminUser) => {
    return formatDateTime(rowData.createdAt);
  };

  const handleChangeFilter = (value: string, name: string) => {
    const newFilters = { ...filters, [name]: value };
    setFilters(newFilters);
  };

  const handleSort = (e: DataTableSortParams) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const clearFilters = () => {
    setFilters(emptyFilters);
  };

  const leftToolbarTemplate = () => {
    const handleClickNew = () => {
      setCurrentRow(null);
      setIsModalOpen(true);
    };

    return (
      <>
        <Button label="Dodaj" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={handleClickNew} />
      </>
    );
  };

  const rightToolbarTemplate = () => {
    return <></>;
  };

  const header = (
    <div className="table-header">
      Lista administratorów systemowych &#160;&#160;
      <div className="p-text-right">
        <Button
          label="Wyczyść filtry"
          icon="pi pi-filter-slash"
          className="p-button-outlined p-mr-2"
          onClick={clearFilters}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <DataTableFilterElements.Text
            initialValue={filters['global']}
            onChangeFilter={handleChangeFilter}
            name={'global'}
            placeholder="Szukaj"
          />
        </span>
      </div>
    </div>
  );

  const actionBodyTemplate = (rowData: AdminUser) => {
    const handleClickOpenModalRow = () => {
      setCurrentRow(rowData);
      setIsModalOpen(true);
    };

    const handleClickDeleteAdminUserRow = () => {
      setCurrentRow(rowData);
      setIsAdminUserDeleteModalOpen(true);
    };

    return (
      <div className="admin-users-actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2 edit-admin-data-button"
          onClick={handleClickOpenModalRow}
        />
        <Tooltip target={`.edit-admin-data-button`} content={'Edytuj dane administratora'} position="bottom" />

        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning delete-admin-button"
          onClick={handleClickDeleteAdminUserRow}
        />
        <Tooltip target={`.delete-admin-button`} content={'Usuń administratora'} position="bottom" />
      </div>
    );
  };

  return (
    <div className="p-grid">
      <div className="p-col-12">
        <div className="card">
          <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
          <div className="datatable-responsive">
            <div className="card datatable-card">
              <DataTable
                ref={dt}
                value={adminUsers}
                header={header}
                className="p-datatable-responsive"
                dataKey="id"
                sortOrder={paginationParameters.order.isAscending ? 1 : -1}
                sortField={paginationParameters.order.orderColumn}
                onSort={handleSort}
                rowHover
                removableSort
                paginator
                rows={paginatorState.rows}
                paginatorTemplate={getPaginatorTemplate(paginatorState, setPaginatorState) as any}
                emptyMessage="Brak administratorów"
              >
                <Column
                  field="email"
                  header="Adres e-mail"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.email}
                      onChangeFilter={handleChangeFilter}
                      name="email"
                      placeholder="Filtruj"
                    />
                  }
                />
                <Column
                  field="firstName"
                  header="Imie"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.firstName}
                      onChangeFilter={handleChangeFilter}
                      name="firstName"
                      placeholder="Filtruj"
                    />
                  }
                />
                <Column
                  field="lastName"
                  header="Nazwisko"
                  sortable
                  filter
                  filterElement={
                    <DataTableFilterElements.Text
                      initialValue={filters.lastName}
                      onChangeFilter={handleChangeFilter}
                      name="lastName"
                      placeholder="Filtruj"
                    />
                  }
                />
                <Column body={createdAtBodyTemplate} field="createdAt" header="Data utworzenia" sortable />
                <Column body={actionBodyTemplate} style={{ width: '140px', textAlign: 'center' }} />
              </DataTable>

              {isModalOpen && (
                <AdminUserModal
                  adminUserData={currentRow}
                  adminRoles={adminRoles}
                  closeModalHandler={closeModalHandler}
                  formik={formik}
                  updateForm={updateForm}
                />
              )}

              {isAdminUserDeleteModalOpen && (
                <AdminUserDeleteModal
                  closeModalHandler={closeAdminUserDeleteModalHandler}
                  adminUser={currentRow as any}
                  deleteAdminUser={deleteAdminUser}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminUsers;
