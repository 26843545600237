import React from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import '../DataTable.css';
import { License } from '../../types/license';
import licensesService from '../../services/ScaleoAdminApiServices/LicensesService';

interface DeleteLicenseDialogProps {
  visible: boolean;
  closeDialog: (b: boolean) => void;
  selectedRows: License[] | License;
}

const DeleteLicenseDialog = ({ visible, closeDialog, selectedRows }: DeleteLicenseDialogProps) => {
  const handleCloseDialog = (changes?: boolean) => {
    closeDialog(changes === true);
  };

  const handleConfirm = async () => {
    const itemsToDelete = Array.isArray(selectedRows) ? selectedRows : [selectedRows];

    await Promise.all(itemsToDelete.map((item) => licensesService.removeLicense(item.id))).then(() => {
      handleCloseDialog(true);
    });
  };

  const dialogFooter = (
    <>
      <Button
        type="reset"
        label="Anuluj"
        icon="pi pi-times"
        className="p-button-text"
        onClick={handleCloseDialog as any}
      />
      <Button type="button" label="Usuń" icon="pi pi-check" className="p-button-text" onClick={handleConfirm} />
    </>
  );

  return (
    <Dialog
      visible={visible}
      breakpoints={{ '896px': '90vw' }}
      style={{ minWidth: '450px' }}
      header={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          Potwierdź
        </span>
      }
      modal
      className="p-fluid"
      footer={dialogFooter}
      onHide={handleCloseDialog}
    >
      {selectedRows ? (
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
          {Array.isArray(selectedRows) && selectedRows.length > 1 ? (
            <span>Czy na pewno chcesz usunąć wybranych klientów?</span>
          ) : (
            <span>Czy na pewno chcesz usunąć klienta?</span>
          )}
        </div>
      ) : (
        ''
      )}
    </Dialog>
  );
};

export default DeleteLicenseDialog;
