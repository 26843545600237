const hasPermissions = (requiredPermissions, userPermissions) => {
  return requiredPermissions
    .map((requiredPermission) => {
      return userPermissions?.includes(requiredPermission);
    })
    .reduce((a, b) => {
      return a && b;
    });
};

const hasOneOfPermissions = (requiredPermissions, userPermissions) => {
  return requiredPermissions
    .map((requiredPermission) => {
      return userPermissions?.includes(requiredPermission);
    })
    .reduce((a, b) => {
      return a || b;
    });
};

export { hasPermissions, hasOneOfPermissions };
