import { Auth } from 'aws-amplify';

export const authenticateUser = (username, password, callback) => {
  Auth.signIn(username, password)
    .then((user) => {
      callback(
        null,
        user.signInUserSession.idToken.jwtToken,
        user.signInUserSession.idToken.payload['custom:scaleo_id'],
        user.username,
      );
      void Auth.currentCredentials().then((info) => {
        console.log(`Logged user with cognito identity id ${info.identityId}`);
      });
    })
    .catch((err) => {
      callback(err);
    });
};

export const signOut = () => {
  Auth.signOut()
    .then((data) => {
      console.log(data);
    })
    .catch((err) => {
      console.log("Couldn't sign out: ", err);
    });
};
