import axios from 'axios';
import { Customer } from '../../types/customer';

class LocationsService {
  async searchLocations(paginationParameters?: any, filters?: any) {
    return axios.post('/locations/search', { filters, paginationParameters });
  }
  async addLocation(data: Customer) {
    return axios.post(`/locations`, data);
  }
  async editLocation(data: Customer, id: string) {
    return axios.put(`/locations/${id}`, data);
  }
  async removeLocation(id: string) {
    return axios.delete(`/locations/${id}`);
  }
}
const locationsService = new LocationsService();
export default locationsService;
