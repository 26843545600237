import { useState, useCallback, useEffect } from 'react';
import { Station } from '../../types/station';
import stationsService from '../../services/ScaleoAdminApiServices/StationsService';
import serviceUsersService from '../../services/ScaleoAdminApiServices/ServiceUsersService';
import customersService from '../../services/ScaleoAdminApiServices/CustomersService';

export const useStationsData = () => {
  const [componentData, setComponentData] = useState<{
    stations: Station[];
    locations: any[];
    customers: any[];
  }>({
    stations: [],
    locations: [],
    customers: [],
  });
  const [filters, setFilters] = useState<any>({});
  const [internalFilters, setInternalFilters] = useState(filters);
  const [paginationParameters, setPaginationParameters] = useState(emptyPaginationParameters);
  const [paginatorState, setPaginatorState] = useState(emptyPaginatorState);

  const fetchStations = useCallback(
    async (_filters: any, _paginationParameters: any) => {
      void Promise.all([
        stationsService.searchStations(_filters, _paginationParameters),
        serviceUsersService.getAllLocations(),
        customersService.searchCustomers({}, { page: { index: 1, size: 10000 }, order: {} }),
      ]).then((response) => {
        setComponentData({
          stations: response[0].data.data.map((s: Station) => ({
            ...s,
            customerId: response[1].find((l: any) => l.id === s.locationId)?.customer?.id,
          })),
          locations: response[1],
          customers: response[2].data.items.map((c: any) => ({ ...c, name: c.displayName })),
        });

        response[0].data.total_pages
          ? setPaginatorState({
              ...paginatorState,
              totalPages: response[0].data.total_pages,
            })
          : setPaginatorState({
              ...paginatorState,
              totalPages: 1,
              currentPage: 1,
            });
      });
    },
    [
      stationsService.searchStations,
      setComponentData,
      setPaginatorState,
      paginatorState,
      serviceUsersService.getAllLocations,
      customersService.searchCustomers,
    ],
  );

  useEffect(() => {
    void fetchStations(internalFilters, paginationParameters);
  }, [internalFilters, paginationParameters]);

  useEffect(() => {
    if (
      paginatorState.currentPage !== paginationParameters.page.index ||
      paginatorState.rows !== paginationParameters.page.size
    ) {
      const params = {
        ...paginationParameters,
        page: {
          index: paginatorState.currentPage,
          size: paginatorState.rows,
        },
      };
      setPaginationParameters(params);
    }
  }, [paginationParameters, paginatorState]);

  const handleSort = (e: any) => {
    setPaginationParameters({
      ...paginationParameters,
      order: {
        isAscending: e.sortOrder === 1 ? true : false,
        orderColumn: e.sortField,
      },
    });
  };

  const changeFilter = (value: any, name: string) => {
    const newFilters: any = { ...internalFilters };
    newFilters[name] = value;

    setFilters(newFilters);
    setInternalFilters(newFilters);
  };

  const clearFilters = () => {
    setFilters({});
    setInternalFilters({});
  };

  const fetchStationsData = useCallback(
    async () => fetchStations(filters, paginationParameters),
    [filters, paginationParameters],
  );

  return {
    fetchStations: fetchStationsData,
    stations: componentData.stations,
    handleSort,
    paginationParameters,
    paginatorState,
    setPaginatorState,
    internalFilters,
    changeFilter,
    clearFilters,
    locations: componentData.locations,
    filters,
    customers: componentData.customers,
  };
};

const emptyPaginationParameters = {
  order: {
    isAscending: true,
    orderColumn: 'id',
  },
  page: {
    index: 1,
    size: 10,
  },
};

const emptyPaginatorState = {
  currentPage: 1,
  totalPages: 1,
  rows: 10,
};
