import axios from 'axios';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uiActions } from '../store/ui-slice';
import { Toast } from 'primereact/toast';

export const AxiosConfigContainer = () => {
  const dispatch = useDispatch();
  const loggedUserToken = useSelector((state) => state.user?.loggedUser?.token);
  const currentLocationId = useSelector((state) => state.user.currentLocationId);
  const currentCompanyId = useSelector((state) => state.user.currentCompanyId);
  const history = useHistory();
  const toast = useRef();
  let inFlightRequests = [];

  const showLoader = (config) => {
    const request = config.method + ' ' + config.url;
    dispatch(uiActions.toggleIsLoading({ isLoading: true }));
    inFlightRequests.push(request);
  };

  const hideLoader = (config) => {
    inFlightRequests = inFlightRequests.filter((item) => item !== config.method + ' ' + config.url);
    if (inFlightRequests.length === 0) {
      setTimeout(() => {
        dispatch(uiActions.toggleIsLoading({ isLoading: false }));
      }, 300);
    }
  };

  const setUpInterceptors = useCallback(async (token, locationId, companyId) => {
    axios.interceptors.request.handlers = [];
    axios.interceptors.response.handlers = [];

    axios.interceptors.request.use(
      function (config) {
        config.baseURL = process.env.REACT_APP_SCALEO_ADMIN_BACKEND_URL;
        if (token) {
          config.headers.common.Authorization = `Bearer ${token}`;
        }
        if (locationId) {
          config.headers.common.location_id = locationId;
        }
        if (companyId) {
          config.headers.common.company_id = companyId;
        }
        if (config.show_loader !== false) {
          showLoader(config);
        }

        return config;
      },
      function (error) {
        return Promise.reject(error);
      },
    );

    axios.interceptors.response.use(
      function (response) {
        hideLoader(response.config);
        return response;
      },
      function (error) {
        console.error(error);
        error.response && error.response.status === 401 && history.replace('/login');
        error.response &&
          error.response.status === 403 &&
          toast.current.show({
            severity: 'error',
            summary: 'Brak uprawnień',
            detail: 'Brak uprawnień do wykonania żądania, skontaktuj się z administratorem systemu.',
            life: 4000,
          });
        error.response && error.response.status.toString().charAt(0) === '5' && history.replace('/error'); // HTTP 5XX
        hideLoader(error.response.config);
        return Promise.reject(error);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    void setUpInterceptors(loggedUserToken, currentLocationId, currentCompanyId);
  }, [setUpInterceptors, currentLocationId, currentCompanyId, loggedUserToken]);

  return <Toast ref={toast} />;
};
