import { Message } from 'primereact/message';

interface FormErrorMessageProps {
  fieldName: string;
  formikInstance: any;
  className?: string;
  withTouchedTrue?: boolean;
}

const FormErrorMessage = ({ fieldName, formikInstance, className, withTouchedTrue }: FormErrorMessageProps) => {
  const touched = withTouchedTrue || formikInstance.touched[fieldName];

  const isFormFieldInvalid = () => touched && formikInstance.errors[fieldName];

  return isFormFieldInvalid() ? (
    <Message
      id={`error-msg-${fieldName}`}
      severity="error"
      text={formikInstance.errors[fieldName]}
      className={className}
    />
  ) : (
    <></>
  );
};

export default FormErrorMessage;
