import axios from 'axios';

class ServiceUsersService {
  async searchServiceUsers(filters: any, paginatorParam: any, globalFilter: any) {
    const users = await axios.post('/service-users/search', { filters, paginatorParam, globalFilter });

    return users.data;
  }
  async getAllLocations() {
    const locations = await axios.get('/service-users/all-locations');

    return locations.data;
  }
  async inviteServiceUser(user: any) {
    return axios.post('/service-users/invitation', user);
  }
  async resendInvitationServiceUser(params: any) {
    return axios.post('/service-users/resend-invitation', params);
  }
  async editServiceUser(user: any, id: string) {
    return axios.put(`/service-users/${id}`, user);
  }
  async deleteServiceUser(id: string) {
    return axios.delete(`/service-users/${id}`);
  }
}

const serviceUsersService = new ServiceUsersService();
export default serviceUsersService;
