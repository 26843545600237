import ReactCountryFlag from 'react-country-flag';

interface CountryOptionProps {
  label: string;
  value: string;
}

const countryOptionTemplate = (option: CountryOptionProps) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
    }}
  >
    <ReactCountryFlag
      svg
      countryCode={option.value.slice(3, 5)}
      aria-label={option.value}
      className="font-size-30px line-height-30px"
      style={{ fontSize: '1.5rem', marginRight: '0.5rem' }}
    />
    <div className="ml-4">{option.label}</div>
  </div>
);

const selectedCountryTemplate = (option: CountryOptionProps) => {
  if (option) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ReactCountryFlag
          svg
          countryCode={option.value.slice(3, 5)}
          aria-label={option.value}
          className="font-size-30px line-height-30px"
          style={{ fontSize: '1.5rem', marginRight: '0.5rem' }}
        />
        <div className="">{option.label}</div>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '1.5rem',
          marginRight: '0.5rem',
          padding: '0.75rem',
        }}
      ></div>
    </>
  );
};

export { countryOptionTemplate, selectedCountryTemplate };
